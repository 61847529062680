.home.start{
  .column{
    position: relative;
    main{
      width: 100%;
    }
    &.logo{
      display: flex;
      align-items: flex-end;
      svg{
        max-width: 600px;
        width: 100%;
        margin-right: 20px;
        margin-left: auto;
      }
    }
    img{
      position: relative;
      width: 100%;
      max-width: 1000px;
      margin-right: auto;
      margin-left: 0;
      transform: translateY(-40%);
      top: -40%;
      @include devices(tablet) {
        transform: translateY(0);
      }
    }
    .textImage {
      padding: 0;
      width: 100%;
      max-width: 600px;
      margin-right: 20px;
      margin-left: auto;
      margin-bottom: 20px;
    }
  }
  .containerWrapper.light{
    display: inline-block;
    padding-top: 20px;
    height: 610px;
    width: 100%;
    @include devices(tablet) {
      height: auto;
    }
  }
  .btn.btn-dark{
    background-color: $colorPrimary;
  }
}