.containerWrapper{
  background-clip: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 40px;
  padding-right: 40px;
  &.white{
    background-color: white;
  }
  &.blue{
    background-color: $colorBlue;
  }
  &.dark{
    background-color: $colorDark;
  }
  &.light{
    background-color: $colorLight;
  }
  &.primary{
    background-color: $colorPrimary;
  }
  &.sec-padding{
    padding-bottom: 150px;
    padding-top: 150px;
    @include devices(tablet) {
      padding: 20px;
    }
  }
  &.sec-margin{
    margin: 50px 0;
    @include devices(tablet) {
      margin: 20px 0;
    }
  }
  @include devices(tablet) {
    padding: 20px;
  }
  .row{
    display: flex;
    flex-direction: row;
    @include devices(tablet) {
      flex-direction: column;
      margin-bottom: 40px;
    }
    .column{
      .row{
        display: flex;
        flex-direction: column;
        height: 100%;
        .textWrapper{
          flex: max-content;
          display: flex;
        }
      }
    }
  }
}