@import '_colors.scss';

@import 'global/_breakpoints.scss';
@import 'global/_bootstrap.min.css';
@import 'global/_theme.scss';
@import 'global/_navigation.scss';
@import 'global/_breadcrumbs.scss';
@import 'global/_loader.scss';
@import 'global/_corporate.scss';
@import 'global/_fontawesome.css';
@import 'global/_simple-line-icons.scss';
@import 'global/_blog.scss';
@import 'global/_footer.scss';
@import 'components/_accordion.scss';
@import 'components/_container.scss';
@import 'components/_textImage.scss';
@import 'components/_contact.scss';
@import 'pages/_startpage.scss';

@import '_skin.scss';
