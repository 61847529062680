.textImageWrapper{
  .textImage, .image {
    svg{
      margin-top: 20px;
    }
    &.white {
      background-color: white;

      h1, h2, h3, h4, h5, h6, p{
        color: $colorDark!important;
      }
      svg{
        stroke: $colorDark;
        fill: $colorDark;
      }
      a.read-more{
        color: $colorPrimary!important;
      }
    }
    &.blue {
      background-color: $colorBlue;

      h1, h2, h3, h4, h5, h6, p{
        color: white!important;
      }
      svg{
        stroke: $colorPrimary;
        fill: $colorPrimary;
      }
      a.read-more{
        color: $colorPrimary!important;
      }
    }
    &.dark {
      background-color: $colorDark;

      h1, h2, h3, h4, h5, h6, p{
        color: white!important;
      }
      svg{
        stroke: white;
        fill: white;
      }
      a.read-more{
        color: $colorPrimary!important;
      }
    }
    &.light {
      background-color: $colorLight;

      h1, h2, h3, h4, h5, h6, p{
        color: $colorDark!important;
      }
      svg{
        stroke: $colorDark;
        fill: $colorDark;
      }
      a.read-more{
        color: $colorPrimary!important;
      }
    }
    &.primary {
      background-color: $colorPrimary;

      h1, h2, h3, h4, h5, h6, p{
        color: white!important;
      }
      svg{
        stroke: white;
        fill: white;
      }
      a.read-more{
        color: white!important;
      }
    }
    .yt-video{
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
  .textImage{
    @include devices(tablet) {
      padding: 20px;
    }
    padding-bottom: 40px;
    .read-more{
      position: absolute;
      bottom: 30px;
      &:hover{
        color: $colorPrimary;
      }
    }
  }
  ul{
    li{
      &::before {
        font: var(--fa-font-solid);
        content: "\f071";
      }
    }
  }
}
.image{
  svg{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
.column{
  .textImage{
    padding: 20px;
    width: 100%;
    h3{
      line-height: 32px;
    }
    h4{
      font-size: 14px;
    }
  }
}
.containerWrapper {
  &.white{
    .textImage{
      .ce-title-top-text{

      }
      h1, h2, h3, h4, h5, h6{
        color: $colorDark;
      }
      a.read-more{
        color: $colorPrimary;
        &:hover{
          color: $colorDark;
        }
      }
    }
    .btn.btn-border {
      border: 1px solid $colorDark;
      color: $colorBlue
    }
    .btn.btn-dark {
      background-color: $colorDark;
      color: $colorBlue
    }
    p{
      color: $colorDark;
    }
  }

  &.light {
    .textImage{
      .ce-title-top-text{
      }
      h1, h2, h3, h4, h5, h6{
        color: $colorDark;
      }
      p{
        color: $colorDark;
      }
      a.read-more{
        color: $colorPrimary;
        &:hover{
          color: $colorDark;
        }
      }
    }
    .btn.btn-border {
      border: 1px solid $colorBlue;
      color: $colorBlue
    }
    .btn.btn-dark {
      background-color: $colorBlue;
      color: white
    }
  }
  &.blue {
    .textImage{
      .ce-title-top-text{

      }
      h1, h2, h3, h4, h5, h6{
        color: white
      }
      p{
        color: white;
      }
      a.read-more{
        color: $colorPrimary;
        &:hover{
          color: white;
        }
      }
    }
    .btn.btn-border {
      border: 1px solid $colorPrimary;
      color: white
    }
    .btn.btn-dark {
      background-color: $colorPrimary;
      color: white
    }
  }
  &.dark {
    .textImage{
      .ce-title-top-text{

      }
      h1, h2, h3, h4, h5, h6{
        color: white
      }
      p{
        color: white;
      }
      a.read-more{
        color: $colorPrimary;
        &:hover{
          color: white;
        }
      }
    }
    .btn.btn-border {
      border: 1px solid $colorPrimary;
      color: white
    }
    .btn.btn-dark {
      background-color: $colorPrimary;
      color: white
    }
  }
  &:not(.sec-padding){

    .textImage{
      padding-top: 20px;
    }
  }
}