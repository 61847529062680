@mixin devices ($breakpoint) { 
	@if $breakpoint == desktop_big {
		@media only screen and (max-width: 1280px) {
		  @content;
		}
	}
	@if $breakpoint == desktop {
		@media only screen and (max-width: 1024px) {
			@content;
		}
	}
	@if $breakpoint == tablet {
		@media only screen and (max-width: 768px) {
			@content;
		}
	}
	@if $breakpoint == mobile {
		@media only screen and (max-width: 481px) {
			@content;
		}
	}
}