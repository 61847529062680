.breadcrumb{
  background-color: white;
  padding: 0;
  .container{
    padding: 0 25px;
    ul{
      list-style-type: none;
      margin: 0;
      margin-top: 5px;
      padding: 0;
      li{
        float: left;
        position: relative;
        i{
          color: $colorDark;
          font-size: 9px;
          top: -1px;
          margin: 0 5px;
        }
      }
    }
  }
}