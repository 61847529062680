$color_1: #888;
$color_2: #999;
$color_3: #c7c7c7;
$color_4: #727272;
$color_5: #fccb06;
$color_6: #000;
$color_7: #fff;
$color_8: #101010;
$color_9: #a0a0a0;
$color_10: #9a9a9a;
$color_11: #9c9c9c;
$color_12: #ecae3d;
$color_13: #a2a2a2;
$color_14: #ebebeb;
$color_15: #f51e46;
$color_16: #747474;
$color_17: #ababab;
$color_18: #a9cd2e;
$color_19: #abacac;
$color_20: #afafaf;
$color_21: #18a50b;
$color_22: #ff2f16;
$color_23: #ccc;
$color_24: #ff971c;
$color_25: #80b604;
$color_26: #04ce47;
$color_27: #242424;
$color_28: #333;
$color_29: yellow;
$color_30: #181818;
$color_31: #454545;
$color_32: #2ecc71;
$font-family_1: 'Raleway', sans-serif;
$background-color_1: #ff971c;
$background-color_2: #fff;
$background-color_3: #000;
$background-color_4: #fccb06;
$background-color_5: #222323;
$background-color_6: rgba(0, 0, 0, 0.5);
$background-color_7: #191919;
$background-color_8: #0f0f0f;
$background-color_9: #464545;
$background-color_10: #383737;
$background-color_11: #292929;
$background-color_12: rgba(0, 0, 0, 0);
$background-color_13: rgba(0, 0, 0, 0.12);
$background-color_14: rgba(0, 0, 0, 0.18);
$background-color_15: rgba(0, 0, 0, 0.23);
$background-color_16: rgba(0, 0, 0, 0.4);
$background-color_17: rgba(0, 0, 0, 0.8);
$background-color_18: #eaeaea;
$background-color_19: #1d1d1d;
$background-color_20: #f0efef;
$background-color_21: rgba(252, 226, 6, 0.9);
$background-color_22: #454545;
$background-color_23: #101010;
$background-color_24: #2d2d2d;
$background-color_25: rgba(0, 0, 0, 0.1);
$background-color_26: rgba(0, 0, 0, 0.7);
$background-color_27: #2b2b2b;
$background-color_28: #a9cd2e;
$background-color_29: #1c1c1c;
$background-color_30: #131313;
$background-color_31: #f5f5f5;
$background-color_32: rgba(252, 203, 6, 0.9);
$background-color_33: #202020;
$background-color_34: #222;
$background-color_35: #e4e4e4;
$background-color_36: #242424;
$background-color_37: #2a2a2a;
$background-color_38: transparent;
$background-color_39: #242930;
$background-color_40: #04ce47;
$background-color_41: rgba(0, 0, 0, 0.2);

/*
Template: 
category: Corporate
Author: Codelayers
Version: 1.0
Design and Developed by: Codelayers
*/
/*
======================================
[ CSS TABLE CONTENT ]
======================================

    [Table of contents]

	1.feature boxes
	2.parallax sections
	3.footer styles

	
======================================
[ END CSS TABLE CONTENT ]
======================================
*/
/*============ Feature boxes ============ */
/* title line
 ------------------------------ */
/* feature box 1
 ------------------------------ */
/* feature box 2
 ------------------------------ */
/* feature box 3
 ------------------------------ */
/* feature box 4
 ------------------------------ */
/* feature box 5
 ------------------------------ */
/* feature box 6
 ------------------------------ */
/*hover shadow style 2*/
/* feature box 7
 ------------------------------ */
/* feature box 8
 ------------------------------ */
/* featurebox 10
 ------------------------------ */
/* feature box 9
 ------------------------------ */
/*style 2*/
/*style 3*/
/* feature box 11
 ------------------------------ */
/*hover*/
/*primary*/
/* feature box 12
 ------------------------------ */
/* feature box 13
 ------------------------------ */
/* feature box 14
 ------------------------------ */
/* feature box 15
 ------------------------------ */
/*dark style*/
/* feature box 16
 ------------------------------ */
/*text box yellow*/
/*text box dark*/
/*dark hover*/
/*white box and dark hover*/
/* feature box 17
 ------------------------------ */
/*primary color*/
/* feature box 18
 ------------------------------ */
/* feature box 19
 ------------------------------ */
/* feature box 20
 ------------------------------ */
/* ---------- feature box 21 ---------- */
/* feature box 22
 ------------------------------ */
/* feature box 23
 ------------------------------ */
/* feature box 24
 ------------------------------ */
/*Hover*/
/*dark*/
/*primary*/
/* feature box 25
 ------------------------------ */
/* footer newsletter
 ------------------------------ */
/* feature box 26
 ------------------------------ */
/* feature box 27
 ------------------------------ */
/*shadow*/
/* feature box 28
 ------------------------------ */
/* feature box 29
 ------------------------------ */
/*dark*/
/*dark*/
/* featurebox 30
 ------------------------------ */
/* feature box 31
 ------------------------------ */
/* featurebox 32
 ------------------------------ */
/* feature box 33
 ------------------------------ */
/* feature box 34
 ------------------------------ */
/* feature box 35
 ------------------------------ */
/* ---------- feature box 36 ---------- */
/* feature box 37
 ------------------------------ */
/* feature box 38
 ------------------------------ */
/* feature box 39
 ------------------------------ */
/* featurebox 40
 ------------------------------ */
/* feature box 41
 ------------------------------ */
/*primary*/
/* feature box 42
 ------------------------------ */
/* feature box 43
 ------------------------------ */
/* feature box 44
 ------------------------------ */
/* feature box 45
 ------------------------------ */
/* feature box 46
 ------------------------------ */
/* feature box 47
 ------------------------------ */
/* featurebox 48
 ------------------------------ */
/*background-white*/
/*background-primary*/
/* featurebox 39
 ------------------------------ */
/* feature box 50
 ------------------------------ */
/* feature box 51
 ------------------------------ */
/* feature box 52
 ------------------------------ */
/* feature box 53
 ------------------------------ */
/*dark*/
/*dark 2*/
/*primary*/
/* feature box 54
 ------------------------------ */
/* feature box 55
 ------------------------------ */
/* feature box 56
 ------------------------------ */
/* feature box 57
 ------------------------------ */
/* feature box 58
 ------------------------------ */
/* feature box 59
 ------------------------------ */
/* feature box 60
 ------------------------------ */
/* featurebox 61
 ------------------------------ */
/* ---------- feature box 62 ---------- */
/* feature box 63
 ------------------------------ */
/*dark*/
/* feature box 64
 ------------------------------ */
/* feature box 65
 ------------------------------ */
/* feature box 66
 ------------------------------ */
/* feature box 67
 ------------------------------ */
/* ---------- feature box 68 ---------- */
/* feature box 69
 ------------------------------ */
/* ---------- feature box 70 ---------- */
/* feature box 71
 ------------------------------ */
/* price table 1
 ------------------------------ */
/*dark active style*/
/* Price Table 2
 -----------------------------------------------*/
/* price table 3
 ------------------------------ */
/* hover styles
 ------------------------------ */
/* corporate pages
 ------------------------------ */
/* innerpage 2
 ------------------------------ */
/*============ Progress circles ============ */
/*============ blog ============ */
/*============ pages sidebar ============ */
/*============ Site map ============ */
/*============ 404 page ============ */
/*============ portfolio single page ============ */
/*============ comment page input ============ */
/*============ blog oagenation ============ */
/* video wrapper
 ------------------------------ */
/* video wrapper
 ------------------------------ */
/* blog inner pages
 ------------------------------ */
/*  blog feature box 2
 ------------------------------ */
/*Contact Form*/
/* contact form */
/*----------------------------RESPONSIVE STYLES------------------------------- */
/* ---------- MAX 320PX ---------- */
/* ---------- MAX 1024PX ---------- */
/* ---------- MAX 991PX ---------- */
/* ---------- MAX 800PX ---------- */
/* ---------- MAX 768PX ---------- */
/* ---------- MAX 767PX ---------- */
/* ---------- MAX 360PX ---------- */
/* ---------- MAX 320PX ---------- */
.ce-title-top-text {
	color: $color_1;
}
.ce-title-top-text.grey-1 {
	color: $color_2;
}
.ce-title-top-text.grey-2 {
	color: $color_3;
}
.ce-sub-text {
	width: 50%;
	margin: 15px auto 0 auto;
}
.ce-sub-text.grey-1 {
	color: $color_4;
}
.ce-sub-text.less-width {
	width: 50%;
	margin: 15px auto 0 auto;
}
.ce-sub-text.align-left {
	width: 80%;
	margin: 15px auto 0 0;
}
.ce-title-line {
	width: 12%;
	height: 2px;
	background-color: $background-color_1;
	margin: 15px auto 0 auto;
}
.ce-title-line.white {
	background-color: $background-color_2 !important;
}
.ce-title-line.align-left {
	width: 12%;
	height: 2px;
	background-color: $background-color_1;
	margin: 15px 88% 0 auto;
}
.ce-title-line.dark {
	background-color: $background-color_3 !important;
}
.ce-feature-box-1 {
	width: 100%;
	padding: 70px;
	text-align: center;
	border: 1px solid #fff;
	background-color: $background-color_2;
	transition: all 0.3s ease-out 0s;
	-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	-moz-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	.icon {
		color: $color_5;
	}
	p {
		color: $color_6;
		opacity: 0.7;
	}
	&:hover {
		border: 1px solid #fccb06;
		background-color: $background-color_4;
		-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		transform: translateY(-8px);
		p {
			color: $color_7;
			opacity: 1;
		}
		.title {
			color: $color_7;
		}
		.icon {
			color: $color_7;
		}
	}
}
.ce-feature-box-1.border-grey {
	border: 1px solid #ebebeb;
}
.ce-feature-box-2 {
	position: relative;
	width: 100%;
	padding: 100px;
	float: left;
	overflow: hidden;
	background-color: $background-color_5;
	transition: all 0.3s ease-out 0s;
	.overlay {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 100px;
		float: left;
		overflow: hidden;
		background-color: $background-color_6;
		transition: all 0.3s ease-out 0s;
	}
	.title {
		position: relative;
		color: $color_7;
		top: 20px;
		transition: all 0.3s ease-out 0s;
	}
	.content {
		position: relative;
		color: $color_7;
		opacity: 0.6;
		top: 25px;
		transition: all 0.3s ease-out 0s;
	}
	a.read-more {
		position: relative;
		top: 135px;
		color: $color_7;
		transition: all 0.3s ease-out 0s;
		&:hover {
			color: $color_7;
		}
	}
	&:hover {
		.title {
			top: 0;
		}
		.content {
			top: 0px;
		}
		a.read-more {
			top: 0px;
		}
	}
}
.ce-feature-box-2.bg2 {
	background-color: $background-color_7;
}
.ce-feature-box-2.bg3 {
	background-color: $background-color_8;
}
.ce-feature-box-2.bg4 {
	background-color: $background-color_3;
}
.ce-feature-box-2.bg5 {
	background-color: $background-color_9;
}
.ce-feature-box-2.bg6 {
	background-color: $background-color_10;
}
.ce-feature-box-2.bg7 {
	background-color: $background-color_11;
}
.ce-feature-box-3 {
	width: 100%;
	float: left;
	padding: 50px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	.icon {
		color: $color_5;
	}
}
.ce-feature-box-4 {
	position: relative;
	width: 100%;
	padding: 0;
	float: left;
	overflow: hidden;
	background-color: $background-color_4;
	transition: all 0.3s ease-out 0s;
	.overlay {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 100px;
		float: left;
		overflow: hidden;
		transition: all 0.3s ease-out 0s;
	}
	.title {
		position: relative;
		color: $color_7;
		top: 20px;
		transition: all 0.3s ease-out 0s;
	}
	.content {
		position: relative;
		color: $color_7;
		opacity: 0.9;
		top: 25px;
		transition: all 0.3s ease-out 0s;
	}
	a.read-more {
		position: relative;
		top: 135px;
		color: $color_7;
		transition: all 0.3s ease-out 0s;
		&:hover {
			color: $color_7;
		}
	}
	&:hover {
		.title {
			top: 0;
		}
		.content {
			top: 0px;
		}
		a.read-more {
			top: 0px;
		}
	}
	.overlay.bg-1 {
		background-color: $background-color_12;
	}
	.overlay.bg-2 {
		background-color: $background-color_13;
	}
	.overlay.bg-3 {
		background-color: $background-color_14;
	}
	.overlay.bg-4 {
		background-color: $background-color_15;
	}
	.overlay.bg-5 {
		background-color: $background-color_16;
	}
}
.ce-feature-box-5 {
	width: 100%;
	float: left;
	padding: 50px;
	background-color: $background-color_2;
	-webkit-box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.03);
	-moz-box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.03);
	box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.03);
	.imgbox-xtiny {
		width: 40px;
		height: 40px;
		background-color: $background-color_3;
	}
	.text-box-right {
		padding-top: 10px;
	}
	.img-box {
		width: 100%;
		float: left;
	}
}
.ce-feature-box-5.dark-style {
	background-color: $background-color_3;
	border: 5px solid #000;
	.title {
		color: $color_7;
	}
}
.ce-feature-box-6 {
	width: 100%;
	float: left;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	.img-box {
		width: 144px;
		height: 144px;
		padding: 9px;
		display: inline-block;
		border-radius: 100%;
		background: #000000;
		background: -moz-linear-gradient(top, #000000 0%, #424242 57%);
		background: -webkit-linear-gradient(top, #000000 0%,#424242 57%);
		background: linear-gradient(to bottom, #000000 0%,#424242 57%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#424242',GradientType=0 );
	}
	.text-box {
		width: 100%;
		padding: 60px;
		float: left;
		background-color: $background-color_2;
		transition: all 0.3s ease-out 0s;
		.quote-icon {
			width: 100%;
			float: left;
			i {
				color: $color_6;
				font-size: 35px;
				transition: all 0.3s ease-out 0s;
			}
		}
	}
	.text-box.border {
		border: 1px solid #ebebeb;
	}
	&:hover {
		.text-box {
			box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.06);
			-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.06);
			transform: translateY(-10px);
			.quote-icon {
				i {
					color: $color_5;
				}
			}
		}
	}
}
.ce-feature-box-6.active {
	.text-box {
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.06);
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.06);
		transform: translateY(-10px);
	}
}
.ce-feature-box-6.h-shadow-2 {
	&:hover {
		.text-box {
			box-shadow: 0 20px 20px rgba(0,0,0,0.14);
			-webkit-box-shadow: 0 20px 20px rgba(0,0,0,0.14);
			transform: translateY(-10px);
		}
	}
}
.ce-feature-box-6.h-shadow-2.active {
	.text-box {
		box-shadow: 0 20px 20px rgba(0,0,0,0.14);
		-webkit-box-shadow: 0 20px 20px rgba(0,0,0,0.14);
		transform: translateY(-10px);
	}
}
.ce-feature-box-7 {
	width: 100%;
	float: left;
	transition: all 0.3s ease-out 0s;
	.img-box {
		img {
			transition: all 0.3s ease-out 0s;
		}
		position: relative;
		width: 100%;
		float: left;
		overflow: hidden;
		.postdate-box {
			position: absolute;
			float: left;
			padding: 8px 20px;
			z-index: 2;
			right: 0px;
			color: $color_7;
			bottom: 0px;
			background-color: $background-color_4;
			box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		}
		.blog-post-info {
			span {
				font-size: 18px;
				color: $color_8;
			}
		}
		.postdate-box.less-padd-1 {
			padding: 20px;
		}
		.postdate-box.less-padd-2 {
			padding: 10px;
		}
	}
	&:hover {
		.img-box {
			img {
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				transform: scale(1.3);
			}
		}
		.postinfo-box.shadow {
			box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.05);
		}
	}
	.postinfo-box {
		width: 100%;
		float: left;
		padding: 100px;
		margin: 0px 0 40px 0;
		background-color: $background-color_2;
		.title {
			padding: 6px 0;
			line-height: 30px;
			border-bottom: 1px solid #fccb06;
			a {
				&:hover {
					color: $color_5;
				}
			}
		}
	}
	.postinfo-box.shadow {
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		transition: all 0.3s ease-out 0s;
	}
	.postinfo-box.no-border {
		border: none;
	}
}
.ce-feature-box-7.hover-shadow {
	&:hover {
		-webkit-box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
		-moz-box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
		box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
	}
}
.ce-feature-box-7.active.hover-shadow {
	-webkit-box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
}
.ce-feature-box-7.border {
	border: 1px solid #e9e9e9;
}
.ce-feature-box-7.less-padd {
	.postinfo-box {
		padding: 60px;
	}
}
.ce-feature-box-7.less-padd-2 {
	.postinfo-box {
		padding: 80px;
	}
}
.ce-feature-box-7.no-margin {
	.postinfo-box {
		margin: 0px 0 0 0;
	}
}
.ce-feature-box-8 {
	width: 100%;
	float: left;
	.main-box {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		overflow: hidden;
		border: 10px solid #fff;
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		.img-box {
			position: relative;
			width: 100%;
			padding: 0px;
			float: left;
			background-color: $background-color_2;
			position: relative;
			width: 100%;
			float: left;
			overflow: hidden;
			.overlay {
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 0;
				padding: 30px;
				float: left;
				transition: all 0.3s ease-out 0s;
				background-color: $background-color_17;
				.small-text {
					position: absolute;
					color: $color_9;
					top: 400px;
					left: 10px;
					opacity: 1;
					transition: all 0.3s ease-out 0s;
				}
				.sc-icons {
					position: absolute;
					width: 100%;
					top: 300px;
					display: inline-block;
					transition: all 0.3s ease-out 0s;
					li {
						padding: 0px;
						margin: 0px;
						display: inline-block;
						a {
							float: left;
							font-size: 15px;
							color: $color_7;
							line-height: 0px;
							margin: 0px;
							padding: 7px;
							display: inline-block;
							border-radius: 100%;
						}
					}
				}
			}
		}
		img {
			transition: all 0.3s ease-out 0s;
		}
		.text-box {
			width: 100%;
			padding: 30px 0;
			float: left;
			background-color: $background-color_18;
			.title {
				color: $color_6;
			}
		}
	}
	&:hover {
		.img-box {
			img {
				-moz-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
		.main-box {
			.img-box {
				.overlay {
					opacity: 1;
					.small-text {
						top: 80px;
					}
					.sc-icons {
						top: 180px;
					}
				}
			}
		}
	}
}
.ce-feature-box-10 {
	position: relative;
	width: 100%;
	float: left;
	.play-btn-img {
		position: absolute;
		width: 130px;
		height: 130px;
		float: left;
		top: 43%;
		left: 47%;
		z-index: 4;
	}
	.content {
		position: absolute;
		width: 100%;
		float: left;
		text-align: center;
		padding: 360px 0 0 0;
		z-index: 3;
	}
	.play-btn-img.pos-1 {
		top: 32%;
		left: 47%;
	}
	.content.pos-1 {
		padding: 300px 0 0 0;
	}
}
.ce-feature-box-9-main {
	width: 100%;
	min-height: 480px;
	float: left;
	.ce-feature-box-9 {
		position: relative;
		width: 100%;
		float: left;
		left: 0px;
		top: -85px;
		padding: 90px;
		z-index: 1;
		background-color: $background-color_2;
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		transition: all 0.3s ease-out 0s;
		.title {
			margin-bottom: 18px;
			color: $color_8;
			span {
				color: $color_8;
				padding: 10px 0;
				border-bottom: 1px solid #fccb06;
			}
		}
		.text {
			color: $color_4;
			padding-top: 10px;
		}
		&:hover {
			-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
			box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
			transform: translateY(-8px);
			.top-box {
				border-left: 1px solid #fccb06;
				border-top: 1px solid #fccb06;
				opacity: 1;
			}
			.bottom-box {
				border-right: 1px solid #fccb06;
				border-bottom: 1px solid #fccb06;
				opacity: 1;
			}
		}
		.img-box {
			width: 100%;
			padding: 5px;
			display: inline-block;
			background-color: $background-color_3;
		}
		.img-box.light {
			background-color: $background-color_20;
		}
		.top-box {
			position: absolute;
			width: 50px;
			height: 50px;
			z-index: 3;
			left: 30px;
			top: 30px;
			opacity: 0.3;
			border-left: 1px solid #000;
			border-top: 1px solid #000;
		}
		.bottom-box {
			position: absolute;
			width: 50px;
			height: 50px;
			z-index: 3;
			right: 30px;
			bottom: 30px;
			opacity: 0.3;
			border-right: 1px solid #000;
			border-bottom: 1px solid #000;
		}
	}
	.ce-feature-box-9.less-padd {
		padding: 50px;
	}
	.ce-feature-box-9.style-2 {
		padding: 80px;
		background-color: $background-color_2;
		.icon {
			padding-top: 0px;
			color: $color_5;
		}
	}
	.ce-feature-box-9.style-3 {
		padding: 80px;
		background-color: $background-color_19;
		.title {
			span {
				color: $color_5;
				border-bottom: 1px solid #333;
			}
		}
	}
}
.ce-feature-box-9-main.more-height-1 {
	min-height: 640px;
}
.ce-feature-box-11 {
	width: 100%;
	float: left;
	.img-box {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		.overlay {
			position: absolute;
			width: 100%;
			padding: 0;
			float: left;
			overflow: hidden;
			.text-box {
				position: relative;
				width: 80%;
				padding: 50px;
				float: left;
				margin-left: 10%;
				top: 320px;
				overflow: hidden;
				background-color: $background-color_2;
				transition: all 0.3s ease-out 0s;
				.title {
					position: relative;
					padding-top: 10px;
					color: $color_6;
					top: 0;
					transition: all 0.3s ease-out 0s;
				}
				.small-text {
					position: relative;
					color: $color_4;
					top: 0;
					transition: all 0.3s ease-out 0s;
				}
				.btn {
					position: relative;
					top: 0;
					transition: all 0.3s ease-out 0s;
				}
			}
		}
		.overlay.primary {
			background-color: $background-color_21;
			.text-box {
				.title {
					color: $color_6;
				}
				.small-text {
					color: $color_6;
					opacity: 0.7;
				}
			}
		}
	}
	&:hover {
		.img-box {
			.overlay {
				.text-box {
					top: 150px;
				}
			}
		}
	}
}
.ce-feature-box-12 {
	width: 100%;
	float: left;
	.icon {
		color: $color_6;
		background-color: $background-color_2;
		border: 1px solid #fff;
	}
	.content {
		color: $color_7;
	}
}
.ce-feature-box-13 {
	width: 100%;
	padding: 0;
	float: left;
	.icon {
		position: relative;
		color: $color_5;
		top: 20px;
		transition: all 0.3s ease-out 0s;
	}
	.title {
		position: relative;
		color: $color_7;
		top: 20px;
		transition: all 0.3s ease-out 0s;
		span {
			padding: 10px 0;
			border-bottom: 1px solid #fccb06;
		}
	}
	.small-text {
		position: relative;
		color: $color_10;
		top: 30px;
		opacity: 0.7;
		transition: all 0.3s ease-out 0s;
	}
	.btn {
		position: relative;
		opacity: 0;
		top: 100px;
		background-color: $background-color_22;
		color: $color_7;
		transition: all 0.3s ease-out 0s;
	}
	&:hover {
		.icon {
			top: -5px;
			color: $color_7;
		}
		.title {
			top: 5px;
			color: $color_7;
		}
		.small-text {
			top: 0;
		}
		.btn {
			top: 0px;
			opacity: 1;
		}
	}
}
.ce-feature-box-14 {
	width: 100%;
	padding: 0 50px 0 50px;
}
.ce-feature-box-14.slider-btn-6 {
	.owl-theme {
		.owl-controls {
			.owl-buttons {
				.owl-prev {
					position: absolute;
					color: $color_4;
					display: inline-block;
					zoom: 1;
					*display: inline;
					margin: 5px;
					left: -172px;
					top: -310px;
					padding: 15px;
					font-size: 14px;
					border-radius: 0px;
					background: url(prev-arrow3.png) center center no-repeat;
					opacity: 1;
					transition: all 0.3s ease-out 0s;
					&:hover {
						background: url(prev-arrow3.png) center center no-repeat;
					}
				}
				.owl-next {
					position: absolute;
					color: $color_4;
					display: inline-block;
					zoom: 1;
					*display: inline;
					margin: 5px;
					right: -172px;
					top: -310px;
					padding: 20px;
					font-size: 14px;
					border-radius: 0px;
					background: url(next-arrow3.png) center center no-repeat;
					opacity: 1;
					transition: all 0.3s ease-out 0s;
					&:hover {
						background: url(next-arrow3.png) center center no-repeat;
					}
				}
			}
			position: relative;
			width: 100%;
			position: absolute;
			margin-top: 25%;
		}
	}
	.owl-carousel {
		.owl-item {
			width: 100%;
			float: left;
			padding: 0 0;
		}
	}
}
.ce-feature-box-15 {
	width: 100%;
	padding: 40px;
	text-align: center;
	border: 1px solid #fff;
	background-color: $background-color_2;
	transition: all 0.3s ease-out 0s;
	-webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.1);
	.icon {
		color: $color_5;
	}
	.title {
		color: $color_6;
	}
	.icon.text-dark {
		color: $color_6;
	}
	p {
		color: $color_6;
		opacity: 0.7;
	}
	&:hover {
		border: 1px solid #101010;
		background-color: $background-color_23;
		border: 1px solid #fccb06;
		background-color: $background-color_4;
		-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		transform: translateY(-8px);
		.title {
			color: $color_7;
		}
		.icon {
			color: $color_7;
			color: $color_6;
			background-color: $background-color_2;
			border: 1px solid #fff;
		}
		p {
			color: $color_7;
			opacity: 0.4;
		}
	}
}
.ce-feature-box-15.active {
	border: 1px solid #101010;
	background-color: $background-color_23;
	border: 1px solid #fccb06;
	background-color: $background-color_4;
	.title {
		color: $color_7;
	}
	.icon {
		color: $color_7;
	}
	p {
		color: $color_7;
		opacity: 0.4;
	}
}
.ce-feature-box-15.dark {
	border: 1px solid #2d2d2d;
	background-color: $background-color_24;
	.icon {
		color: $color_5;
		color: $color_5;
		border: 1px solid #3a3a3a;
	}
	.title {
		color: $color_11;
	}
	p {
		color: $color_7;
		opacity: 0.7;
	}
	&:hover {
		.icon {
			color: $color_5;
			border: 1px solid #3a3a3a;
			background-color: $background-color_24;
		}
		.title {
			color: $color_5;
		}
	}
}
.ce-feature-box-16 {
	width: 100%;
	float: left;
	.main-box {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		overflow: hidden;
		.img-box {
			width: 100%;
			padding: 0px;
			float: left;
			position: relative;
			width: 100%;
			float: left;
			overflow: hidden;
		}
		img {
			transition: all 0.3s ease-out 0s;
		}
		.text-box {
			width: 100%;
			min-height: 260px;
			padding: 0px;
			float: left;
		}
		.inner-box {
			position: absolute;
			width: 100%;
			padding: 60px;
			float: left;
			top: 470px;
			background-color: $background-color_2;
			transition: all 0.3s ease-out 0s;
			.title {
				color: $color_6;
			}
			.subtext {
				color: $color_5;
				transition: all 0.3s ease-out 0s;
			}
			.small-text {
				color: $color_4;
				opacity: 1;
			}
		}
		.inner-box.bg-dark {
			background-color: $background-color_23;
		}
	}
	&:hover {
		.img-box {
			img {
				-moz-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
		.main-box {
			.inner-box {
				.title {
					color: $color_7;
				}
				.subtext {
					color: $color_7;
					transition: all 0.3s ease-out 0s;
					color: $color_7;
				}
				.small-text {
					color: $color_7;
					opacity: 1;
					color: $color_7;
					opacity: 1;
				}
			}
		}
		.text-box {
			.inner-box {
				top: 400px;
				background-color: $background-color_4;
			}
		}
	}
	.text-box {
		.inner-box {
			.sc-icons {
				width: 100%;
				float: left;
				padding: 30px 0 0 0;
				transition: all 0.3s ease-out 0s;
				li {
					padding: 0px;
					margin: 0px;
					display: inline-block;
					a {
						width: 40px;
						height: 40px;
						float: left;
						font-size: 15px;
						color: $color_7;
						line-height: 0px;
						margin: 0px;
						display: inline-block;
						border-radius: 100%;
						&:hover {
							color: $color_7;
						}
					}
				}
			}
		}
	}
}
.ce-feature-box-16.primary {
	.main-box {
		.inner-box {
			background-color: $background-color_4;
			.subtext {
				color: $color_6;
				opacity: 0.7;
			}
		}
	}
}
.ce-feature-box-16.dark {
	.main-box {
		.inner-box {
			background-color: $background-color_23;
			.subtext {
				color: $color_7;
				opacity: 0.7;
				color: $color_7;
			}
			.title {
				color: $color_7;
			}
			.small-text {
				color: $color_7;
				opacity: 0.5;
			}
		}
	}
	.text-box {
		.inner-box {
			.sc-icons {
				li {
					a {
						color: $color_7;
						&:hover {
							color: $color_5;
						}
					}
				}
			}
		}
	}
	&:hover {
		.main-box {
			.inner-box {
				background-color: $background-color_2;
				.title {
					color: $color_6;
				}
				.subtext {
					color: $color_6;
				}
				.small-text {
					color: $color_6;
					opacity: 0.5;
				}
			}
		}
		.text-box {
			.inner-box {
				.sc-icons {
					li {
						a {
							color: $color_6;
						}
					}
				}
			}
		}
	}
}
.ce-feature-box-16.white-2 {
	&:hover {
		.main-box {
			.inner-box {
				background-color: $background-color_3;
				.title {
					color: $color_7;
				}
				.subtext {
					color: $color_7;
				}
				.small-text {
					color: $color_7;
					opacity: 0.5;
				}
			}
		}
		.text-box {
			.inner-box {
				.sc-icons {
					li {
						a {
							color: $color_7;
						}
					}
				}
			}
		}
	}
}
.ce-feature-box-17 {
	width: 100%;
	float: left;
	.text-box {
		position: relative;
		width: 100%;
		padding: 80px;
		float: left;
		overflow: hidden;
		background-color: $background-color_3;
		.icon {
			position: relative;
			color: $color_7;
			top: 45px;
			transition: all 0.3s ease-out 0s;
		}
		.title {
			position: relative;
			padding-top: 10px;
			color: $color_7;
			top: 42px;
			transition: all 0.3s ease-out 0s;
		}
		.small-text {
			position: relative;
			color: $color_7;
			opacity: 0;
			top: 150px;
			transition: all 0.3s ease-out 0s;
		}
	}
	&:hover {
		.text-box {
			.icon {
				top: 0px;
			}
			.small-text {
				top: 2px;
				opacity: 0.5;
			}
			.title {
				top: 2px;
			}
		}
	}
	.text-box.bg-2 {
		background-color: $background-color_23;
	}
}
.ce-feature-box-17.primary {
	.text-box {
		.overlay {
			position: absolute;
			width: 100%;
			padding: 0;
			left: 0px;
			top: 0px;
			z-index: 1;
			background-color: $background-color_25;
		}
		background-color: $background-color_4;
		.icon {
			z-index: 2;
			color: $color_7;
		}
		.title {
			z-index: 2;
			color: $color_7;
		}
	}
	&:hover {
		.text-box {
			.small-text {
				opacity: 0.7;
				z-index: 2;
				color: $color_7;
			}
		}
	}
}
.ce-feature-box-18 {
	width: 100%;
	padding: 40px;
	text-align: center;
	border: 1px solid #fff;
	background-color: $background-color_2;
	transition: all 0.3s ease-out 0s;
	-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	.icon {
		color: $color_6;
		opacity: 0.5;
	}
	.subtext {
		color: $color_4;
	}
	&:hover {
		.title {
			color: $color_6;
			color: $color_7;
		}
		border: 1px solid #000;
		background-color: $background-color_3;
		.subtext {
			color: $color_7;
		}
		.icon {
			color: $color_7;
			opacity: 1;
		}
	}
}
.ce-feature-box-18.active {
	border: 1px solid #000;
	background-color: $background-color_3;
	.title {
		color: $color_7;
	}
	.subtext {
		color: $color_7;
	}
	.icon {
		color: $color_7;
		opacity: 1;
	}
}
.ce-feature-box-19-main {
	width: 100%;
	padding: 0;
	display: inline-block;
	background-color: $background-color_2;
}
.ce-feature-box-19 {
	width: 100%;
	float: left;
	padding: 0px;
	.img-list {
		width: 100%;
		float: left;
	}
	.img-box-main {
		width: 100%;
		padding: 0;
		float: left;
		background-color: $background-color_2;
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-moz-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	}
	.img-box {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		overflow: hidden;
		.overlay {
			position: absolute;
			width: 80%;
			height: 80%;
			padding: 50px;
			top: 530px;
			left: 10%;
			float: left;
			background-color: $background-color_4;
			transition: all 0.3s ease-out 0s;
			.title {
				color: $color_7;
			}
			.content {
				color: $color_7;
			}
			.sub-text {
				color: $color_7;
				padding-bottom: 12px;
			}
			.sc-icons {
				width: 100%;
				float: left;
				padding: 0px;
				transition: all 0.3s ease-out 0s;
				li {
					padding: 0px;
					margin: 0px;
					display: inline-block;
					a {
						width: 30px;
						height: 30px;
						float: left;
						font-size: 15px;
						color: $color_7;
						line-height: 0px;
						margin: 0px;
						display: inline-block;
						border-radius: 100%;
						&:hover {
							color: $color_6;
						}
					}
				}
			}
		}
	}
	&:hover {
		.img-box {
			.overlay {
				top: 10%;
			}
		}
	}
}
.ce-feature-box-19.no-shadow {
	.img-box-main {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
}
.ce-feature-box-20 {
	width: 100%;
	float: left;
	overflow: hidden;
	transition: all 0.3s ease-out 0s;
	.imgbox {
		position: relative;
		width: 100%;
		float: left;
		overflow: hidden;
		img {
			overflow: hidden;
		}
		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			float: left;
			.text-box {
				width: 100%;
				float: left;
				padding: 260px 0 0 0;
				.title-box {
					width: 90%;
					line-height: 30px;
					position: relative;
					top: 0px;
					color: $color_6;
					padding: 30px;
					text-align: left;
					transition: all 0.3s ease-out 0s;
					background-color: $background-color_2;
				}
			}
		}
	}
	&:hover {
		.imgbox {
			.overlay {
				.text-box {
					.title-box {
						background-color: $background-color_4;
						top: -50px;
						.title {
							color: $color_7;
						}
					}
				}
			}
		}
		-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		transform: translateY(-8px);
	}
}
.ce-feature-box-21 {
	position: relative;
	width: 100%;
	float: left;
	padding: 0px;
	transition: all 0.3s ease-out 0s;
	.img-box {
		position: relative;
		width: 100%;
		float: left;
		padding: 0px;
		transition: all 0.3s ease-out 0s;
		.overlay {
			position: absolute;
			width: 100%;
			z-index: 1;
			background-color: $background-color_16;
		}
	}
	.text-box {
		width: 100%;
		float: left;
		padding: 100px;
		min-height: 600px;
		background-color: $background-color_2;
		.post-date-box {
			color: $color_8;
			bottom: 0px;
			left: 512px;
			font-size: 22px;
			padding: 35px 0 0 0;
			width: 120px;
			height: 120px;
			background-color: $background-color_2;
			span {
				color: $color_8;
				font-size: 16px;
			}
		}
	}
	&:hover {
		h3 {
			color: $color_12;
		}
	}
	.text-box.top-space {
		top: 60px;
	}
}
.ce-feature-box-21.active {
	h3 {
		color: $color_12;
	}
}
.ce-feature-box-22 {
	width: 100%;
	float: left;
	-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	-moz-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	.main-box {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		overflow: hidden;
		.img-box {
			position: relative;
			width: 100%;
			padding: 0px;
			float: left;
			background-color: $background-color_2;
			position: relative;
			width: 100%;
			float: left;
			overflow: hidden;
			.overlay {
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 0;
				padding: 30px;
				float: left;
				transition: all 0.3s ease-out 0s;
				background-color: $background-color_26;
				.small-text {
					position: absolute;
					color: $color_7;
					top: 400px;
					left: 10px;
					transition: all 0.3s ease-out 0s;
				}
				.sc-icons {
					position: absolute;
					width: 100%;
					top: 300px;
					display: inline-block;
					transition: all 0.3s ease-out 0s;
					li {
						padding: 0px;
						margin: 0px;
						display: inline-block;
						a {
							float: left;
							font-size: 15px;
							color: $color_7;
							line-height: 0px;
							margin: 0px;
							padding: 7px;
							display: inline-block;
							border-radius: 100%;
						}
					}
				}
			}
		}
		.img-box.white {
			background-color: $background-color_2;
		}
		img {
			transition: all 0.3s ease-out 0s;
		}
		.text-box {
			width: 100%;
			padding: 40px 0 40px 0;
			float: left;
			background-color: $background-color_2;
			.title {
				color: $color_8;
			}
			.subtext {
				color: $color_7;
				padding: 3px 10px;
				display: inline-block;
				margin-top: 15px;
				background-color: $background-color_4;
			}
		}
	}
	.main-box.shadow {
		transition: all 0.3s ease-out 0s;
		box-shadow: 0 20px 20px rgba(0,0,0,0.14);
		-webkit-box-shadow: 0 20px 20px rgba(0,0,0,0.14);
		.text-box {
			padding: 40px 0 40px 0;
		}
	}
	&:hover {
		.img-box {
			img {
				-moz-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
		.main-box {
			.img-box {
				.overlay {
					opacity: 1;
					.small-text {
						top: 80px;
					}
					.sc-icons {
						top: 180px;
					}
				}
			}
		}
		.main-box.shadow {
			-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
			box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
			transform: translateY(-8px);
		}
	}
}
.ce-feature-box-23 {
	width: 100%;
	float: left;
	padding: 0 0 0 0;
	.title {
		color: $color_7;
	}
	.icon {
		margin-bottom: 15px;
		color: $color_6;
		background-color: $background-color_2;
	}
	.small-text {
		color: $color_4;
	}
}
.ce-feature-box-23.no-padding {
	padding: 0 0 0 0;
}
.ce-feature-box-24 {
	position: relative;
	width: 100%;
	float: left;
	z-index: 5;
	background-color: $background-color_2;
	.img-box {
		position: relative;
		width: 100%;
		float: left;
		overflow: hidden;
		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			float: left;
			z-index: 2;
			padding: 230px 0 0 60px;
			background: rgba(0, 0, 0, 0.5);
			transition: all 0.3s ease-out 0s;
			.title {
				color: $color_7;
			}
			a {
				&:hover {
					color: $color_7;
				}
			}
		}
	}
	&:hover {
		.img-box {
			.overlay {
				background: rgba(252, 203, 6, 0.9);
				a {
					&:hover {
						color: $color_6;
					}
					color: $color_7;
				}
			}
		}
	}
}
.ce-feature-box-24.active {
	.img-box {
		.overlay {
			background: rgba(252, 203, 6, 0.9);
			a {
				color: $color_7;
			}
		}
	}
}
.ce-feature-box-24.text-box {
	width: 100%;
	float: left;
	padding: 80px 65px 65px 65px;
	background-color: $background-color_2;
	min-height: 540px;
}
.ce-feature-box-24.text-box.dark {
	background-color: $background-color_27;
	.title {
		color: $color_7;
	}
	.subtext {
		color: $color_13;
	}
	.content {
		color: $color_4;
	}
}
.ce-feature-box-24.text-box.primary {
	background-color: $background-color_4;
	.title {
		color: $color_7;
	}
	.subtext {
		color: $color_7;
	}
	.content {
		color: $color_7;
	}
}
.ce-feature-box-25 {
	width: 100%;
	float: left;
	padding: 20px;
	background-color: $background-color_2;
	.newsletter {
		width: 76%;
		height: 65px;
		float: left;
		padding: 10px;
		font-size: 18px;
		color: $color_8;
		margin-right: 20px;
		text-align: left;
		background: #fff;
		border: 1px solid #fff;
	}
	.newsletter-btn {
		width: 21%;
		height: 63px;
		float: left;
		padding: 0px;
		color: $color_14;
		text-transform: uppercase;
		border: 1px solid #101010;
		background-color: $background-color_23;
	}
	.newsletter-btn.prim {
		color: $color_7;
		border: 1px solid #a9cd2e;
		background-color: $background-color_28;
	}
}
.ce-feature-box-26 {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0;
	.text-box {
		width: 100%;
		float: left;
		padding: 100px;
		background-color: $background-color_2;
	}
}
.ce-feature-box-27 {
	width: 100%;
	float: left;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	.text-box {
		position: relative;
		width: 100%;
		padding: 80px;
		float: left;
		overflow: hidden;
		background-color: $background-color_2;
		transition: all 0.3s ease-out 0s;
		.icon {
			position: relative;
			color: $color_5;
			top: 45px;
			transition: all 0.3s ease-out 0s;
		}
		.title {
			position: relative;
			padding-top: 10px;
			color: $color_8;
			top: 42px;
			transition: all 0.3s ease-out 0s;
		}
		.small-text {
			position: relative;
			color: $color_4;
			opacity: 0;
			top: 150px;
			transition: all 0.3s ease-out 0s;
		}
	}
	.text-box.border {
		border: 1px solid #ebebeb;
		background-color: $background-color_2;
	}
	&:hover {
		.text-box {
			.icon {
				top: 0px;
				color: $color_7;
			}
			.small-text {
				top: 2px;
				opacity: 0.9;
				color: $color_4;
			}
			.title {
				top: 2px;
				color: $color_7;
			}
			background-color: $background-color_3;
			-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
			box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
			transform: translateY(-8px);
		}
	}
	.text-box.bg-2 {
		background-color: $background-color_29;
	}
}
.ce-feature-box-28 {
	width: 100%;
	float: left;
	.text-box {
		width: 100%;
		float: left;
		border-radius: 5px;
		margin-bottom: 40px;
	}
}
.ce-feature-box-29 {
	position: relative;
	width: 100%;
	float: left;
	padding: 70px;
	top: -75px;
	margin-bottom: 85px;
	background-color: $background-color_2;
	-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	transition: all 0.3s ease-out 0s;
	z-index: 10;
	.icon {
		color: $color_6;
	}
	&:hover {
		transform: translateY(-8px);
	}
	.bg-icon {
		position: absolute;
		width: 130px;
		height: 130px;
		float: left;
		top: 25px;
		opacity: 0.04;
		z-index: 1;
	}
}
.ce-feature-box-29.no-shadow {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ce-feature-box-29.dark {
	background-color: $background-color_3;
	-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	transition: all 0.3s ease-out 0s;
	.icon {
		color: $color_7;
	}
	.title {
		color: $color_7;
	}
	.content {
		color: $color_7;
		opacity: 0.7;
	}
}
.ce-feature-box-29.dark-2 {
	background-color: $background-color_19;
	.icon {
		color: $color_5;
	}
	.title {
		color: $color_7;
	}
	.content {
		color: $color_4;
		opacity: 1;
	}
	a.read-more {
		color: $color_2;
		opacity: 0.7;
	}
}
.ce-feature-box-30 {
	width: 100%;
	float: left;
	padding: 75px;
	background-color: $background-color_2;
	.icon {
		color: $color_5;
	}
	.subtext {
		color: $color_4;
	}
}
.ce-feature-box-30.dark {
	background-color: $background-color_3;
	.title {
		color: $color_7;
	}
	.icon {
		color: $color_7;
	}
}
.ce-feature-box-30.dark-2 {
	background-color: $background-color_30;
	.title {
		color: $color_7;
	}
	.icon {
		color: $color_7;
	}
}
.ce-feature-box-30.b-right {
	border-right: 1px solid #ebebeb;
}
.ce-feature-box-31 {
	width: 100%;
	float: left;
	padding: 0px;
	background-color: $background-color_2;
	transition: all 0.3s ease-out 0s;
	.text-box {
		width: 100%;
		float: left;
		padding: 70px;
		.date-ifo {
			padding: 5px 10px;
			color: $color_7;
			background-color: $background-color_4;
		}
	}
	&:hover {
		-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		transform: translateY(-8px);
	}
}
.ce-feature-box-31.bg-light {
	background-color: $background-color_31;
}
.ce-feature-box-32 {
	width: 100%;
	float: left;
	.icon {
		color: $color_7;
	}
}
.ce-feature-box-33 {
	width: 100%;
	float: left;
	padding: 0px;
	transition: all 0.3s ease-out 0s;
	.img-list {
		width: 100%;
		float: left;
	}
	.img-box {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		overflow: hidden;
		img {
			transition: all 0.3s ease-out 0s;
		}
		.overlay {
			position: absolute;
			width: 85%;
			height: 85%;
			margin: 8% 0 0 7.5%;
			padding: 40px;
			top: 500px;
			left: 0px;
			float: left;
			background-color: $background-color_32;
			transition: all 0.3s ease-out 0s;
			p {
				color: $color_7;
			}
			.title {
				color: $color_7;
			}
			.sub-text {
				color: $color_7;
			}
			.sc-icons {
				width: 100%;
				padding: 0px;
				transition: all 0.3s ease-out 0s;
				li {
					padding: 0px;
					margin: 0px;
					display: inline-block;
					a {
						width: 40px;
						height: 40px;
						float: left;
						font-size: 15px;
						color: $color_7;
						line-height: 0px;
						margin: 0px;
						display: inline-block;
						border-radius: 100%;
						&:hover {
							color: $color_6;
						}
					}
				}
			}
		}
		.overlay.less-padd {
			padding: 45px;
		}
	}
	&:hover {
		.img-box {
			img {
				-moz-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
			.overlay {
				top: 0px;
			}
		}
	}
	.shape-1 {
		position: absolute;
		width: 50px;
		height: 40px;
		padding: 0;
		left: 54px;
		top: 301px;
		float: left;
		z-index: 4;
	}
	.shape-1.pos-2 {
		left: 0px;
		top: 167px;
	}
	.shape-1.pos-3 {
		left: 153px;
		top: 167px;
	}
	.shape-2 {
		position: absolute;
		width: 50px;
		height: 40px;
		padding: 0;
		left: 326px;
		top: 210px;
		float: left;
		z-index: 4;
	}
	.shape-2.pos-2 {
		left: 19px;
		top: 275px;
	}
	.shape-3 {
		position: absolute;
		width: 50px;
		height: 40px;
		padding: 0;
		left: 85px;
		top: 70px;
		float: left;
		z-index: 2;
	}
	.shape-3.pos-2 {
		left: 181px;
		top: 108px;
	}
	.shape-4 {
		position: absolute;
		width: 50px;
		height: 40px;
		padding: 0;
		left: 258px;
		top: 408px;
		float: left;
		z-index: 5;
	}
}
.ce-feature-box-34 {
	width: 100%;
	float: left;
	margin-bottom: 100px;
	.title {
		font-size: 40px;
		font-weight: 600;
		color: $color_5;
	}
	.icon {
		color: $color_7;
		border: 1px solid rgba(255, 255, 255, 0.3);
		margin-bottom: 15px;
	}
}
.ce-feature-box-34.no-margin {
	margin-bottom: 0;
}
.ce-feature-box-35 {
	width: 100%;
	float: left;
	.newsletter {
		width: 76%;
		height: 65px;
		float: left;
		padding: 15px;
		font-size: 16px;
		color: $color_8;
		margin-right: 10px;
		text-align: left;
		background: #fff;
		border-radius: 5px;
		border: 1px solid #fff;
	}
	.newsletter-btn {
		width: 21%;
		height: 63px;
		float: left;
		padding: 0px;
		color: $color_7;
		border-radius: 5px;
		text-transform: uppercase;
		border: 1px solid #fccb06;
		background-color: $background-color_4;
	}
}
.ce-feature-box-36 {
	width: 100%;
	float: left;
	padding: 0px;
	transition: all 0.3s ease-out 0s;
	.text-box {
		width: 100%;
		float: left;
		padding: 100px;
		min-height: 600px;
		background-color: $background-color_2;
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	}
	.text-box.min-hei-2 {
		min-height: 660px;
	}
	.text-box.border {
		border: 1px solid #ebebeb;
	}
}
.ce-feature-box-37 {
	width: 100%;
	float: left;
	padding: 50px;
	background-color: $background-color_2;
	transition: all 0.3s ease-out 0s;
	.imgbox-xtiny {
		width: 40px;
		height: 40px;
		background-color: $background-color_3;
	}
	.text-box-right {
		padding-top: 10px;
	}
	&:hover {
		-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		transform: translateY(-8px);
	}
	.title {
		a {
			&:hover {
				color: $color_15;
			}
		}
	}
	.date-box {
		width: 100%;
		float: left;
		margin-bottom: 15px;
		border-bottom: 1px solid #fccb06;
		span {
			float: left;
			padding: 5px 20px;
			color: $color_7;
			background-color: $background-color_4;
		}
	}
}
.ce-feature-box-37.shadow {
	box-shadow: 0 20px 20px rgba(0,0,0,0.14);
	-webkit-box-shadow: 0 20px 20px rgba(0,0,0,0.14);
}
.ce-feature-box-38 {
	width: 100%;
	float: left;
	padding: 40px;
	background-color: $background-color_33;
	transition: all 0.3s ease-out 0s;
	.icon {
		color: $color_16;
	}
	.title {
		color: $color_17;
	}
	&:hover {
		background-color: $background-color_2;
		.title {
			color: $color_6;
		}
	}
}
.ce-feature-box-38.active {
	background-color: $background-color_2;
	.title {
		color: $color_6;
	}
}
.ce-feature-box-39 {
	width: 100%;
	padding: 100px;
}
.ce-feature-box-40 {
	position: relative;
	width: 100%;
	float: left;
	background-color: $background-color_2;
	.play-btn-img {
		position: absolute;
		width: 130px;
		height: 130px;
		float: left;
		top: 205px;
		left: 38%;
		z-index: 4;
	}
	.play-btn-img.pos-1 {
		top: 103px;
		left: 41%;
	}
	.img-box {
		position: absolute;
		width: 90%;
		float: left;
		z-index: 2;
	}
	.pattren-box {
		position: absolute;
		width: 90%;
		height: 550px;
		top: -12%;
		left: 10%;
		float: left;
		z-index: 1;
		background: url(../images/127.jpg) 0 0 repeat;
	}
}
.ce-feature-box-41 {
	width: 100%;
	float: left;
	padding: 60px;
	text-align: center;
	background-color: $background-color_2;
	transition: all 0.3s ease-out 0s;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	.title {
		font-size: 20px;
	}
	.icon {
		color: $color_18;
		font-weight: 500;
		background-color: $background-color_2;
		border: 1px solid #e4e4e4;
	}
	.img-circle {
		width: 170px;
		height: 170px;
		border-radius: 100%;
		padding: 15px;
		display: inline-block;
		margin-bottom: 30px;
		background-color: $background-color_31;
		.inner-circle {
			width: 140px;
			height: 140px;
			border-radius: 100%;
			overflow: hidden;
			background-color: $background-color_2;
		}
	}
	.img-circle.border {
		background-color: $background-color_2;
		border: 1px solid #e4e4e4;
	}
}
.ce-feature-box-41.border {
	background-color: $background-color_2;
	border: 1px solid #ebebeb;
}
.ce-feature-box-41.primary {
	background-color: $background-color_4;
	.img-circle {
		background-color: $background-color_31;
	}
	.title {
		color: $color_7;
	}
	.content {
		color: $color_7;
	}
}
.ce-feature-box-42 {
	width: 100%;
	float: left;
	padding: 70px;
	border: 1px solid #ebebeb;
	background-color: $background-color_2;
}
.ce-feature-box-42.noborder {
	border: none;
}
.ce-feature-box-42.nobg {
	border: none;
	background: none;
}
.ce-feature-box-43 {
	width: 100%;
	float: left;
	border: 2px solid #202020;
	transition: all 0.3s ease-out 0s;
	.icon {
		color: $color_7;
		background-color: $background-color_33;
	}
	.icon.primary {
		color: $color_5;
	}
	.title {
		color: $color_7;
	}
	.text-box {
		width: 100%;
		padding: 20px;
		.top-line {
			width: 20px;
			height: 2px;
			margin: 0 auto;
			background-color: $background-color_4;
		}
	}
	&:hover {
		border: 2px solid #fff;
	}
}
.ce-feature-box-43.padding-1 {
	padding: 40px;
}
.ce-feature-box-43.bgdark {
	border: 2px solid #202020;
	background-color: $background-color_33;
}
.ce-feature-box-44 {
	position: relative;
	width: 100%;
	float: left;
	padding: 0px;
	margin-bottom: 135px;
	transition: all 0.3s ease-out 0s;
	.name-box {
		position: absolute;
		width: 84%;
		float: left;
		padding: 40px;
		z-index: 11;
		left: 8%;
		bottom: -25%;
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		background-color: $background-color_2;
		transition: all 0.3s ease-out 0s;
		.name {
			span {
				border-bottom: 1px solid #fccb06;
				padding: 8px 0;
			}
		}
		.desig {
			padding-top: 20px;
		}
	}
	.img-list {
		width: 100%;
		float: left;
	}
	.img-box {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		overflow: hidden;
		img {
			transition: all 0.3s ease-out 0s;
		}
		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			padding: 40px;
			float: left;
			opacity: 0;
			background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 91%);
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 91%);
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 91%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
			transition: all 0.3s ease-out 0s;
			.text-box {
				position: absolute;
				width: 79%;
				padding: 30px;
				float: left;
				top: 520px;
				border: 2px solid rgba(255, 255, 255, 0.4);
				transition: all 0.3s ease-out 0s;
				p {
					color: $color_7;
				}
				.title {
					color: $color_7;
				}
				.sub-text {
					color: $color_7;
				}
				.sc-icons {
					width: 100%;
					padding: 0px;
					transition: all 0.3s ease-out 0s;
					li {
						padding: 0px;
						margin: 0px;
						display: inline-block;
						a {
							width: 40px;
							float: left;
							font-size: 15px;
							color: $color_7;
							line-height: 0px;
							margin: 0px;
							display: inline-block;
							border-radius: 100%;
							&:hover {
								color: $color_5;
							}
						}
					}
				}
				.overlay {
					top: 0px;
				}
			}
		}
	}
	&:hover {
		.img-box {
			img {
				-moz-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
			.overlay {
				.text-box {
					top: 220px;
					border: 2px solid rgba(255, 255, 255, 0.4);
				}
				opacity: 1;
			}
		}
	}
}
.ce-feature-box-45 {
	width: 100%;
	padding: 0px;
	float: left;
	transition: all 0.3s ease-out 0s;
	.ce-post-img {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		.overlay {
			position: absolute;
			width: 100%;
			padding: 150px 0 0 0;
			float: left;
			text-align: center;
			z-index: 1;
			opacity: 0;
			transition: all 0.3s ease-out 0s;
			i {
				position: absolute;
				color: $color_7;
				top: 250px;
				left: 187px;
				opacity: 0;
				font-size: 22px;
				font-weight: 100;
				text-align: center;
				transition: all 0.3s ease-out 0s;
			}
		}
		.postinfo-tag {
			position: absolute;
			padding: 10px 20px;
			float: left;
			color: $color_6;
			top: 20px;
			left: 20px;
			border-radius: 70px;
			background-color: $background-color_4;
		}
	}
	.text-box {
		height: 320px;
		padding: 70px;
		background-color: $background-color_2;
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		transition: all 0.3s ease-out 0s;
		.date-info-box {
			width: 100%;
			color: $color_6;
			float: left;
			margin-top: 25px;
			border-top: 1px solid #e9e9e9;
			.box-left {
				width: 70%;
				float: left;
				color: $color_19;
				padding-top: 15px;
			}
			.box-right {
				width: 30%;
				float: right;
				text-align: right;
				color: $color_19;
				padding-top: 15px;
			}
		}
		.title {
			margin-bottom: 10px;
			line-height: 28px;
			a {
				&:hover {
					color: $color_5;
				}
			}
		}
	}
	&:hover {
		.ce-post-img {
			.overlay {
				opacity: 1;
				background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
				background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%);
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 );
				i {
					top: 150px;
					opacity: 1;
				}
			}
		}
		box-shadow: 0 20px 20px rgba(0,0,0,0.14);
		-webkit-box-shadow: 0 20px 20px rgba(0,0,0,0.14);
		transform: translateY(-10px);
	}
	.info-badge {
		position: absolute;
		padding: 20px;
		right: 20px;
		top: 20px;
		color: $color_7;
		padding: 5px 20px;
		border-radius: 5px;
		background-color: $background-color_4;
		z-index: 3;
		.icon {
			padding-right: 8px;
		}
	}
}
.ce-feature-box-46 {
	position: relative;
	width: 100%;
	float: left;
	border-radius: 5px;
	padding: 70px;
	z-index: 1;
	overflow: visible;
	background-color: $background-color_2;
	.img-box {
		position: absolute;
		width: 130px;
		height: 130px;
		left: 42%;
		top: -15%;
		border-radius: 100%;
		overflow: hidden;
		padding: 5px;
		z-index: 3;
		background-color: $background-color_2;
	}
	.img {
		width: 120px;
		height: 120px;
		border-radius: 100%;
		overflow: hidden;
	}
	.title {
		color: $color_6;
		font-size: 20px;
		margin-top: 30px;
	}
	.subtitle {
		color: $color_5;
	}
}
.ce-feature-box-47 {
	width: 100%;
	text-align: center;
	transition: all 0.3s ease-out 0s;
	.icon {
		color: $color_5;
	}
	.title {
		color: $color_7;
	}
	.subtext {
		color: $color_7;
	}
}
.ce-feature-box-48 {
	width: 100%;
	float: left;
	overflow: hidden;
	min-height: 300px;
	background-color: $background-color_34;
	.img-box {
		width: 100%;
		float: left;
		overflow: hidden;
		img {
			width: 504px;
			height: auto;
			float: left;
		}
	}
	.text-box {
		width: 100%;
		float: left;
		padding: 100px;
	}
}
.ce-feature-box-48.white {
	background-color: $background-color_2;
}
.ce-feature-box-48.primary {
	background-color: $background-color_4;
}
.ce-feature-box-49 {
	position: relative;
	width: 100%;
	float: left;
	.text-box {
		position: absolute;
		width: 60%;
		float: left;
		top: 200px;
		left: 150px;
		padding: 80px;
		z-index: 2;
		background-color: $background-color_4;
	}
	.text-box.pos-1 {
		top: 402px;
		left: 320px;
	}
	.text-box.pos-2 {
		top: 368px;
		left: 320px;
	}
}
.ce-feature-box-50 {
	width: 100%;
	padding: 0;
	float: left;
	.text-box {
		width: 100%;
		float: left;
		padding: 50px;
		background-color: $background-color_2;
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		transition: all 0.3s ease-out 0s;
	}
	.btn-box {
		width: 100%;
		float: left;
		padding: 50px;
		border-top: 1px solid #ebebeb;
		background-color: $background-color_2;
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	}
	.icon.primary {
		color: $color_5;
	}
}
.ce-feature-box-50.border {
	border: 1px solid #ebebeb;
}
.ce-feature-box-51 {
	width: 100%;
	float: left;
	.text-box {
		width: 100%;
		float: left;
		padding: 60px;
		background-color: $background-color_2;
		margin-bottom: 25px;
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	}
	.img-box {
		width: 130px;
		height: 130px;
		padding: 5px;
		display: inline-block;
		background-color: $background-color_3;
	}
	.text-box-right {
		padding-top: 20px;
	}
}
.ce-feature-box-52 {
	width: 100%;
	padding: 0px;
	float: left;
	transition: all 0.3s ease-out 0s;
	.ce-post-img {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		.overlay {
			position: absolute;
			width: 100%;
			padding: 0;
			float: left;
			text-align: center;
			z-index: 1;
			transition: all 0.3s ease-out 0s;
			i {
				position: absolute;
				color: $color_7;
				opacity: 0;
				font-size: 22px;
				font-weight: 100;
				text-align: center;
				transition: all 0.3s ease-out 0s;
			}
		}
		.postinfo-tag {
			position: absolute;
			padding: 10px 20px;
			float: left;
			color: $color_6;
			top: 20px;
			left: 20px;
			border-radius: 70px;
			background-color: $background-color_4;
		}
	}
	.text-box {
		padding: 30px 0 0 0;
		background-color: $background-color_2;
		-webkit-box-shadow: 0 0px 0px rgba(0,0,0,0.1);
		box-shadow: 0 0px 0px rgba(0,0,0,0.1);
		transition: all 0.3s ease-out 0s;
		.date-info-box {
			width: 100%;
			color: $color_20;
			float: left;
			margin-top: 25px;
			border-top: 1px solid #e9e9e9;
			.box-left {
				width: 70%;
				float: left;
				padding-top: 15px;
			}
			.box-right {
				width: 30%;
				float: right;
				text-align: right;
				padding-top: 15px;
			}
		}
		.title {
			margin-bottom: 10px;
			line-height: 28px;
			a {
				&:hover {
					color: $color_5;
				}
			}
		}
	}
	.text-box.padd-1 {
		padding: 50px;
	}
	.text-box.shadow {
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-moz-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	}
	.info-badge {
		position: absolute;
		padding: 20px;
		right: 20px;
		bottom: 0;
		color: $color_6;
		padding: 5px 20px;
		background-color: $background-color_2;
		z-index: 3;
		.icon {
			padding-right: 8px;
		}
	}
	.info-badge.primary {
		color: $color_7;
		background-color: $background-color_4;
	}
}
.ce-feature-box-53 {
	width: 100%;
	padding: 50px;
	float: left;
	border: 1px solid #ebebeb;
	background-color: $background-color_2;
	transition: all 0.3s ease-out 0s;
}
.ce-feature-box-53.dark {
	border: 1px solid #000;
	background-color: $background-color_3;
	.title {
		color: $color_7;
	}
	.content {
		color: $color_4;
	}
	.icon {
		color: $color_4;
	}
}
.ce-feature-box-53.dark-2 {
	border: 1px solid #131313;
	background-color: $background-color_30;
	.title {
		color: $color_7;
	}
	.content {
		color: $color_4;
	}
	.icon {
		color: $color_4;
	}
}
.ce-feature-box-53.primary {
	border: 1px solid #fccb06;
	background-color: $background-color_4;
	.title {
		color: $color_7;
	}
	.content {
		color: $color_7;
	}
	.icon {
		color: $color_7;
	}
}
.ce-feature-box-54 {
	position: relative;
	width: 100%;
	float: left;
	padding: 90px 180px;
	top: -80px;
	z-index: 2;
	margin-bottom: 80px;
	background-color: $background-color_2;
	-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	.newsletter {
		width: 76%;
		height: 65px;
		float: left;
		padding: 15px;
		font-size: 16px;
		color: $color_8;
		margin-right: 10px;
		text-align: left;
		background: #fff;
		border-radius: 5px;
		border: 1px solid #ebebeb;
	}
	.newsletter-btn {
		width: 21%;
		height: 63px;
		float: left;
		padding: 0px;
		color: $color_7;
		border-radius: 5px;
		text-transform: uppercase;
		border: 1px solid #000;
		background-color: $background-color_3;
	}
}
.ce-feature-box-55 {
	width: 100%;
	float: left;
	margin-bottom: 100px;
	.img-box {
		position: relative;
		width: 100%;
		float: left;
		z-index: 1;
		overflow: hidden;
		img {
			transition: all 0.3s ease-out 0s;
		}
	}
	.text-box {
		position: relative;
		width: 90%;
		float: left;
		padding: 40px 36px;
		z-index: 2;
		top: -50px;
		left: 5%;
		background-color: $background-color_2;
		transition: all 0.3s ease-out 0s;
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-moz-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	}
	&:hover {
		.text-box {
			background-color: $background-color_4;
			.title {
				color: $color_7;
			}
			.small-text {
				color: $color_7;
			}
			a.read-more {
				color: $color_7;
			}
		}
		.img-box {
			img {
				-moz-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}
}
.ce-feature-box-56 {
	position: relative;
	width: 100%;
	float: left;
	padding: 0px;
	transition: all 0.3s ease-out 0s;
	.text-box {
		position: relative;
		width: 90%;
		float: left;
		padding: 80px;
		background-color: $background-color_2;
		.quote-icon {
			position: absolute;
			left: 42%;
			top: -35px;
			text-align: center;
			z-index: 2;
			i {
				color: $color_5;
				font-size: 70px;
			}
		}
	}
}
.ce-feature-box-57 {
	width: 100%;
	float: left;
	.text-box {
		position: relative;
		width: 100%;
		padding: 60px;
		float: left;
		background-color: $background-color_2;
		transition: all 0.3s ease-out 0s;
		.act-ribbon {
			position: absolute;
			width: 100px;
			height: 80px;
			top: 0px;
			right: 0px;
			float: left;
		}
		.price {
			font-weight: 600;
			font-size: 36px;
			color: $color_8;
		}
		.features-list {
			margin: 0 auto 0 auto;
			padding: 0;
			width: 100%;
			li {
				color: $color_4;
				float: left;
				margin: 0;
				font-size: 15px;
				padding: 6px 0;
				width: 100%;
				i {
					color: $color_21;
					font-size: 16px;
					padding-right: 7px;
				}
				i.icon-red {
					color: $color_22;
				}
			}
		}
	}
}
.ce-feature-box-57.active {
	.text-box {
		-webkit-box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
	}
}
.ce-feature-box-57.primary.active {
	.text-box {
		background-color: $background-color_4;
		.features-list {
			li {
				color: $color_7;
			}
		}
		.price {
			color: $color_7;
		}
		.title {
			color: $color_7;
		}
	}
}
.ce-feature-box-58 {
	width: 100%;
	padding: 0;
	.text-box {
		width: 100%;
		padding: 60px;
		border: 1px solid #ebebeb;
		.top-line {
			position: relative;
			width: 20px;
			height: 3px;
			left: 0;
			top: 0;
			background-color: $background-color_4;
		}
	}
	.text-box.shadow {
		border: none;
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-moz-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	}
	.text-box.bg-white {
		border: 1px solid #fff;
		background-color: $background-color_2;
	}
}
.ce-feature-box-59 {
	width: 100%;
	padding: 0;
	float: left;
	.img-box {
		position: relative;
		width: 100%;
		padding: 0;
		float: left;
		overflow: hidden;
		border-radius: 5px;
		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			padding: 50px;
			float: left;
			overflow: hidden;
			background-color: $background-color_16;
			a.btn {
				position: absolute;
				top: 450px;
				transition: all 0.3s ease-out 0s;
			}
		}
		img {
			transition: all 0.3s ease-out 0s;
		}
	}
	&:hover {
		.img-box {
			.overlay {
				a.btn {
					top: 250px;
				}
			}
			img {
				-moz-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}
}
.ce-feature-box-60 {
	width: 100%;
	text-align: center;
	transition: all 0.3s ease-out 0s;
	.icon {
		color: $color_5;
	}
	.title {
		color: $color_5;
		font-size: 60px;
		margin-bottom: 20px;
	}
	.subtext {
		color: $color_7;
	}
}
.ce-feature-box-61 {
	width: 100%;
	float: left;
	padding: 55px;
	text-align: center;
	background-color: $background-color_2;
	.img-circle {
		.img {
			background-color: $background-color_35;
		}
	}
}
.ce-feature-box-62 {
	position: relative;
	width: 100%;
	float: left;
	padding: 0px;
	transition: all 0.3s ease-out 0s;
	.text-box {
		width: 100%;
		float: left;
		padding: 0;
	}
	.text-box.border {
		border: 1px solid #ebebeb;
	}
}
.ce-feature-box-63 {
	width: 100%;
	float: left;
	padding: 70px;
	background-color: $background-color_2;
	-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	transition: all 0.3s ease-out 0s;
	.icon {
		color: $color_6;
	}
	&:hover {
		transform: translateY(-8px);
	}
}
.ce-feature-box-63.no-shadow {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ce-feature-box-63.dark {
	background-color: $background-color_3;
	-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
	transition: all 0.3s ease-out 0s;
	.icon {
		color: $color_7;
	}
	.title {
		color: $color_7;
	}
	.content {
		color: $color_7;
		opacity: 0.7;
	}
}
.ce-feature-box-64 {
	position: relative;
	width: 100%;
	float: left;
	padding: 70px;
	background-color: $background-color_2;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.04);
	transition: all 0.3s ease-out 0s;
	.icon {
		color: $color_6;
	}
	&:hover {
		transform: translateY(-8px);
		.b-line {
			width: 100%;
			background-color: $background-color_3;
		}
		.b-line-2 {
			width: 0%;
			background-color: $background-color_4;
		}
	}
	.b-line {
		position: absolute;
		width: 0%;
		float: right;
		height: 3px;
		bottom: 0px;
		left: 0px;
		z-index: 1;
		background-color: $background-color_3;
		transition: all 0.3s ease-out 0s;
	}
	.b-line-2 {
		position: absolute;
		width: 100%;
		float: left;
		height: 3px;
		bottom: 0px;
		left: 0px;
		z-index: 2;
		background-color: $background-color_4;
		transition: all 0.3s ease-out 0s;
	}
}
.ce-feature-box-64.top-pos {
	top: -60px;
}
.ce-feature-box-64.bmargin {
	margin-bottom: 110px;
}
.ce-feature-box-64.no-shadow {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ce-feature-box-65 {
	position: relative;
	width: 100%;
	float: left;
	.img-box {
		position: absolute;
		width: 90%;
		float: left;
		z-index: 2;
	}
	.pattren-box {
		position: absolute;
		width: 90%;
		height: 480px;
		top: -12%;
		left: 10%;
		float: left;
		z-index: 1;
		background: url(../images/127.jpg) 0 0 repeat;
	}
}
.ce-feature-box-66 {
	width: 100%;
	text-align: center;
	padding-bottom: 30px;
	span {
		padding: 0 5px;
	}
}
.ce-feature-box-67 {
	width: 100%;
	padding: 0;
	float: left;
	.img-box {
		position: relative;
		width: 100%;
		padding: 0;
		float: left;
		.text-box {
			position: absolute;
			padding: 20px;
			float: left;
			bottom: 0px;
			left: 0px;
			span {
				position: absolute;
				padding: 20px 40px;
				background-color: $background-color_2;
				bottom: 0px;
				left: 0px;
			}
		}
	}
}
.ce-feature-box-68 {
	position: relative;
	width: 100%;
	float: left;
	padding: 0px;
	transition: all 0.3s ease-out 0s;
	.img-box {
		position: absolute;
		width: 100%;
		float: left;
		padding: 0px;
		z-index: 1;
		transition: all 0.3s ease-out 0s;
		.overlay {
			position: absolute;
			width: 100%;
			z-index: 1;
			background-color: $background-color_16;
		}
	}
	.text-box {
		position: absolute;
		width: 100%;
		float: left;
		left: -20px;
		top: 20px;
		padding: 100px;
		min-height: 600px;
		background-color: $background-color_4;
		z-index: 2;
		.post-date-box {
			color: $color_8;
			bottom: 0px;
			left: 512px;
			font-size: 22px;
			padding: 35px 0 0 0;
			width: 120px;
			height: 120px;
			background-color: $background-color_2;
			span {
				color: $color_8;
				font-size: 16px;
			}
		}
	}
	&:hover {
		h3 {
			color: $color_12;
		}
	}
	.text-box.top-space {
		top: 60px;
	}
}
.ce-feature-box-68.active {
	h3 {
		color: $color_12;
	}
}
.ce-feature-box-69 {
	position: relative;
	width: 100%;
	padding: 0;
	float: left;
	.img-box {
		position: relative;
		width: 100%;
		padding: 0;
		float: left;
		z-index: 3;
	}
	.shape-1 {
		position: absolute;
		width: 50px;
		height: 40px;
		padding: 0;
		left: 0px;
		top: 250px;
		float: left;
		z-index: 4;
	}
	.shape-1.pos-2 {
		left: 0px;
		top: 167px;
	}
	.shape-1.pos-3 {
		left: 153px;
		top: 167px;
	}
	.shape-1.pos-4 {
		left: 47px;
		top: 286px;
	}
	.shape-2 {
		position: absolute;
		width: 50px;
		height: 40px;
		padding: 0;
		left: 220px;
		top: 210px;
		float: left;
		z-index: 4;
	}
	.shape-2.pos-2 {
		left: 19px;
		top: 275px;
	}
	.shape-2.pos-3 {
		left: 290px;
		top: 275px;
	}
	.shape-3 {
		position: absolute;
		width: 50px;
		height: 40px;
		padding: 0;
		left: 85px;
		top: 70px;
		float: left;
		z-index: 2;
	}
	.shape-3.pos-2 {
		left: 181px;
		top: 108px;
	}
	.shape-4 {
		position: absolute;
		width: 50px;
		height: 40px;
		padding: 0;
		left: 147px;
		top: 324px;
		float: left;
		z-index: 5;
	}
	.shape-4.pos-1 {
		left: 263px;
		top: 369px;
	}
	.text-box {
		width: 100%;
		padding: 50px;
		float: left;
		background-color: $background-color_2;
		box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
		-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	}
	.scicon-box {
		position: absolute;
		width: 100%;
		padding: 30px;
		float: left;
		top: 300px;
		opacity: 0;
		transition: all 0.3s ease-out 0s;
		z-index: 50;
		background-color: $background-color_4;
		p {
			color: $color_7;
		}
		.sub-text {
			color: $color_5;
		}
		.sc-icons {
			width: 100%;
			padding: 0px;
			transition: all 0.3s ease-out 0s;
			li {
				padding: 0px;
				margin: 0px;
				display: inline-block;
				a {
					width: 40px;
					float: left;
					font-size: 15px;
					color: $color_7;
					line-height: 0px;
					margin: 0px;
					display: inline-block;
					border-radius: 100%;
					&:hover {
						color: $color_6;
					}
				}
			}
		}
	}
	&:hover {
		.scicon-box {
			top: 140px;
			opacity: 1;
		}
	}
}
.ce-feature-box-69.scicon-box {
	.title {
		color: $color_7;
	}
}
.ce-feature-box-70 {
	position: relative;
	width: 100%;
	float: left;
	padding: 0px;
	transition: all 0.3s ease-out 0s;
	.text-box {
		width: 100%;
		float: left;
		padding: 0;
	}
	.text-box.border {
		border: 1px solid #ebebeb;
	}
	.input-1 {
		background-color: $background-color_19;
		border: 1px solid #1d1d1d;
		color: $color_23;
		float: left;
		height: 45px;
		margin-bottom: 15px;
		padding: 8px 8px 8px 20px;
		transition: all 0.3s ease-out 0s;
		width: 100%;
		&:focus {
			border: 1px solid #fccb06;
			outline: medium none !important;
			transition: all 0.3s ease-out 0s;
		}
	}
	.lable-text {
		color: $color_8;
	}
	.textaria-1 {
		background-color: $background-color_19;
		border: 1px solid #1d1d1d;
		color: $color_23;
		float: left;
		height: 100px;
		margin-bottom: 15px;
		padding: 8px 8px 8px 20px;
		transition: all 0.3s ease-out 0s;
		width: 100%;
		&:focus {
			border: 1px solid #fccb06;
			outline: medium none !important;
			transition: all 0.3s ease-out 0s;
		}
	}
	.form-body.bg-dark {
		.lable-text {
			color: $color_7;
		}
	}
}
.ce-feature-box-71 {
	width: 100%;
	padding: 0;
	.text-box {
		width: 100%;
		padding: 0 0 0 50px;
		.top-line {
			position: relative;
			width: 20px;
			height: 3px;
			left: -50px;
			top: 24px;
			background-color: $background-color_4;
		}
	}
}
.ce-price-table-1 {
	width: 100%;
	padding: 20px;
	float: left;
	margin-top: 50px;
	transition: all 0.3s ease-out 0s;
	background-color: $background-color_2;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	.inner-box {
		width: 100%;
		padding: 80px 40px;
		float: left;
		background-color: $background-color_2;
		transition: all 0.3s ease-out 0s;
		.price-circle {
			width: 130px;
			height: 130px;
			padding: 38px 0 0;
			margin: 0 auto;
			background-color: $background-color_2;
			border-bottom: 1px solid #fccb06;
			transition: all 0.3s ease-out 0s;
			.price {
				color: $color_24;
				font-size: 70px;
				padding: 8px 0 0 0;
				font-weight: 700;
				text-align: center;
				transition: all 0.3s ease-out 0s;
				sup {
					position: relative;
					font-size: 30px;
					font-weight: 400;
					top: -40px;
					font-style: normal;
				}
			}
			span {
				font-size: 17px;
				font-style: normal;
				font-weight: 500;
				padding-top: 10px;
				color: $color_24;
			}
		}
	}
	ul.plan_features {
		width: 100%;
		float: left;
		text-align: center;
		padding: 0px;
		margin: 0px 0px 50px 0;
		li {
			padding: 8px 0;
			font-size: 16px;
			transition: all 0.3s ease-out 0s;
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
.ce-price-table-1.no-border {
	border: 1px solid #fff;
}
.ce-price-table-1.active {
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
	-webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.03);
}
.ce-price-table-1.dark.active {
	background-color: $background-color_3;
	.inner-box {
		background-color: $background-color_3;
		.price-circle {
			background-color: $background-color_3;
		}
		.title {
			color: $color_7;
		}
	}
}
.ce-price-table-2 {
	position: relative;
	width: 100%;
	padding: 40px 0 70px 0;
	float: left;
	text-align: center;
	transition: all 0.3s ease-out 0s;
	background-color: $background-color_36;
	.price {
		color: $color_7;
		font-size: 26px;
		font-weight: bold;
		line-height: 20px;
		padding: 35px 0;
		text-align: center;
		sup {
			font-size: 22px;
			font-style: normal;
		}
		i {
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
		}
	}
	.title-box {
		width: 100%;
		color: $color_7;
		float: left;
		padding: 45px 0 15px 0;
		vertical-align: middle;
		border-bottom: 1px solid #393939;
		.title {
			color: $color_7;
		}
	}
	.features-list {
		width: 100%;
		padding: 0;
		margin: 0px;
		float: left;
		li {
			width: 100%;
			padding: 15px 0;
			margin: 0px;
			float: left;
			color: $color_4;
			border-bottom: 1px solid #393939;
			i.green-4 {
				color: $color_25;
				font-size: 18px;
			}
			i.red {
				color: $color_22;
				font-size: 18px;
			}
			i.white {
				color: $color_7;
				font-size: 18px;
			}
			&:last-child {
				border-bottom: none;
			}
			&:nth-child(even) {
				background-color: $background-color_37;
			}
		}
	}
}
.ce-price-table-2.border-right {
	border-right: 1px solid rgba(255, 255, 255, 0.5);
	border-right: 1px solid #393939;
}
.ce-price-table-2.first-table {
	.title-box {
		padding: 15px 0 15px 0;
	}
	margin-top: 83px;
}
.ce-price-table-2.active {
	.features-list {
		li {
			&:nth-child(even) {
				background-color: $background-color_1;
			}
			color: $color_7;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		}
	}
	background-color: $background-color_1;
	padding-bottom: 100px;
	padding-top: 65px;
	.title-box {
		color: $color_7;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
}
.pricetable-top-bg-strip {
	width: 100%;
	padding: 0px;
	margin: 0px;
	float: left;
	height: 25px;
	background-color: $background-color_38;
}
.ce-price-table-3 {
	position: relative;
	width: 100%;
	padding: 30px;
	float: left;
	background-color: $background-color_31;
	transition: all 0.3s ease-out 0s;
	.ribbon {
		position: absolute;
		width: 115px;
		height: 145px;
		right: -9px;
		top: -14px;
		float: right;
	}
	.price-box {
		width: 100%;
		margin: 0 auto;
		color: $color_26;
		padding: 30px 0 20px 0;
		border-top: 1px solid rgba(220, 220, 220, 0.3);
		transition: all 0.3s ease-out 0s;
		.price {
			color: $color_26;
			font-size: 36px;
			padding: 10px 0 0 0;
			font-weight: 700;
			text-align: center;
			transition: all 0.3s ease-out 0s;
			i {
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
			}
			sup {
				font-size: 18px;
				font-style: normal;
			}
		}
	}
	.list-box {
		width: 100%;
		float: left;
		padding: 50px;
		margin: 5px 0 0 0;
		background-color: $background-color_2;
	}
	ul.plan_features {
		width: 100%;
		float: left;
		text-align: center;
		padding: 0px;
		margin: 0px 0px 50px 0;
		li {
			padding: 15px 0;
			border-bottom: 1px solid rgba(220, 220, 220, 0.3);
			transition: all 0.3s ease-out 0s;
			&:last-child {
				border-bottom: none;
			}
		}
	}
	&:hover {
		background-color: $background-color_39;
		.title {
			color: $color_7;
		}
		.list-box {
			background-color: $background-color_40;
			ul.plan_features {
				li {
					color: $color_7;
				}
			}
			.btn.btn-prim {
				color: $color_27;
				background-color: $background-color_2;
			}
		}
		.price-box {
			border-top: 1px solid rgba(255, 255, 255, 0.1);
		}
	}
}
.ce-price-table-3.style-2 {
	background-color: $background-color_2;
	.list-box {
		background-color: $background-color_31;
	}
}
.ce-price-table-3.active {
	background-color: $background-color_39;
	.title {
		color: $color_7;
	}
	.list-box {
		background-color: $background-color_40;
		ul.plan_features {
			li {
				color: $color_7;
			}
		}
		.btn.btn-prim {
			color: $color_27;
			background-color: $background-color_2;
		}
	}
	.price-box {
		border-top: 1px solid rgba(255, 255, 255, 0.1);
	}
}
.input-1 {
	background-color: $background-color_2;
	border: 1px solid #e4e4e4;
	color: $color_8;
	float: left;
	height: 45px;
	margin-bottom: 15px;
	padding: 8px;
	transition: all 0.3s ease-out 0s;
	width: 100%;
	&:focus {
		border: 1px solid #fccb06;
		outline: medium none !important;
		transition: all 0.3s ease-out 0s;
	}
}
.lable-text {
	color: $color_8;
}
.textaria-1 {
	background-color: $background-color_2;
	border: 1px solid #e4e4e4;
	color: $color_7;
	float: left;
	height: 100px;
	margin-bottom: 15px;
	padding: 8px;
	transition: all 0.3s ease-out 0s;
	width: 100%;
	&:focus {
		border: 1px solid #fccb06;
		outline: medium none !important;
		transition: all 0.3s ease-out 0s;
	}
}
.form-body.bg-dark {
	.lable-text {
		color: $color_7;
	}
}
.cp-feature-box-1 {
	width: 100%;
	padding: 0px;
	float: left;
	min-height: 645px;
	.img-box {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		.overlay {
			width: 100%;
			height: 100%;
			padding: 0px;
			float: left;
			z-index: 2;
			background-color: $background-color_41;
		}
		.text-box {
			position: absolute;
			width: 80%;
			padding: 50px;
			float: left;
			left: 0px;
			bottom: -185px;
			z-index: 3;
			background-color: $background-color_2;
			transition: all 0.3s ease-out 0s;
			.sc-icons-box {
				position: absolute;
				width: 80%;
				padding: 0px;
				margin: 0px;
				top: -53px;
				left: 0px;
				float: left;
				opacity: 0;
				overflow: hidden;
				z-index: 3;
				background-color: $background-color_1;
				transition: all 0.3s ease-out 0s;
				.sc-icons {
					width: 100%;
					margin: 0px;
					padding-left: 0px;
					text-align: center;
					li {
						display: inline-block;
						a {
							padding: 15px 7px;
							color: $color_7;
							text-align: center;
							display: inline-block;
						}
					}
				}
			}
		}
	}
	&:hover {
		.img-box {
			.text-box {
				.sc-icons-box {
					left: 110px;
					opacity: 1;
				}
				background-color: $background-color_23;
				.title {
					color: $color_7;
				}
			}
		}
	}
}
.cp-feature-box-1.active {
	.img-box {
		.text-box {
			.sc-icons-box {
				left: 110px;
				opacity: 1;
			}
			background-color: $background-color_23;
			.title {
				color: $color_7;
			}
		}
	}
}
.cp-feature-box-2 {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0;
	.text-box {
		width: 100%;
		float: left;
		padding: 100px;
		background-color: $background-color_2;
	}
}
.percent {
	width: 180px;
	height: 180px;
	margin: 0 auto;
}
.percent-2 {
	width: 200px;
	height: 200px;
	margin: 0 auto;
}
.percent-3 {
	width: 200px;
	height: 200px;
	margin: 0 auto;
}
.percent.align-left {
	margin: 0;
}
.percent.align-left.title {
	padding: 0 0 0 20px;
}
.percent-4 {
	width: 150px;
	height: 150px;
	margin: 0 auto;
}
.percent-5 {
	width: 150px;
	height: 150px;
	margin: 0 auto;
}
.ce-post-holder-1 {
	width: 100%;
	padding: 0px;
	float: left;
	transition: all 0.3s ease-out 0s;
	box-shadow: 0 20px 20px rgba(0,0,0,0.14);
	-webkit-box-shadow: 0 20px 20px rgba(0,0,0,0.14);
	.ce-post-img {
		position: relative;
		width: 100%;
		padding: 0px;
		float: left;
		.arrow-up {
			position: absolute;
			width: 0;
			height: 0;
			left: 175px;
			bottom: 0px;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 20px solid #fff;
			z-index: 2;
		}
		.arrow-down {
			position: absolute;
			width: 0;
			height: 0;
			left: 175px;
			top: 0px;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 20px solid #fff;
		}
	}
	.text-box {
		height: 274px;
		padding: 60px 50px 50px 50px;
		.blog-post-info {
			span {
				color: $color_24;
			}
		}
	}
	.text-box.one {
		height: 274px;
	}
	&:hover {
		-webkit-box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.2);
		transform: translateY(-8px);
	}
}
.pages-sidebar-item {
	float: left;
	margin-bottom: 60px;
	width: 100%;
	.social-links {
		float: left;
		margin: 0;
		padding: 0;
		width: 100%;
		li {
			float: left;
			margin: 0;
			padding: 0;
			a {
				color: $color_8;
				float: left;
				padding: 0 20px 0 0;
				&:hover {
					color: $color_24;
				}
			}
		}
	}
}
.pages-newsletter {
	border: 1px solid #e4e4e4;
	float: left;
	height: 45px;
	padding: 10px;
	width: 100%;
}
.bg2-rightcol-links {
	float: left;
	padding: 0;
	width: 100%;
}
.pages-sidebar-item-title {
	font-size: 20px;
	margin-bottom: 25px;
}
.pages-sidebar-links {
	float: left;
	padding: 0;
	width: 100%;
	li {
		border-bottom: 1px solid #ececec;
		float: left;
		width: 100%;
		a {
			float: left;
			padding: 10px 0;
			width: 100%;
			&:hover {
				color: $color_24;
			}
		}
		a.active {
			color: $color_24;
		}
		&:last-child {
			border-bottom: medium none;
		}
	}
}
.pages-tags {
	margin: 0;
	padding: 0;
	li {
		margin: 0;
		padding: 0;
		a {
			background-color: $background-color_2;
			border: 1px solid #e9e9e9;
			color: $color_4;
			float: left;
			margin: 0 10px 10px 0;
			padding: 5px 15px;
			&:hover {
				background-color: $background-color_23;
				color: $color_4;
			}
		}
		a.active {
			background-color: $background-color_23;
			color: $color_4;
		}
	}
}
ul.sitemap {
	margin: 0;
	padding: 0;
	li {
		margin: 0 0 8px;
		a {
			color: $color_24;
			&:hover {
				text-decoration: underline;
			}
		}
		ul {
			padding-left: 30px;
		}
	}
}
.error_holder {
	margin: 0 auto;
	padding: 0;
	text-align: center;
	width: 60%;
	.title {
		font-size: 110px;
		font-weight: 400;
		line-height: 65px;
		margin: 0;
		padding: 30px 0;
	}
}
.error_holder.two {
	text-align: left;
	width: 100%;
}
.error_title_big {
	font-size: 200px;
	font-weight: bold;
	line-height: 65px;
	margin: 0;
	padding: 30px 0;
}
.error-search-box {
	float: left;
	margin: 0;
	padding: 0;
	width: 100%;
	.email_input {
		border: 1px solid #e4e4e4;
		color: $color_4;
		height: 50px;
		width: 70%;
		padding: 15px;
	}
	.input_submit {
		background-color: $background-color_1;
		border: 1px solid #ff971c;
		color: $color_7;
		height: 50px;
		transition: all 0.3s ease-out 0s;
		width: 20%;
		&:hover {
			background-color: $background-color_36;
			border: 1px solid #242424;
		}
	}
}
.error-title-big {
	font-size: 200px;
	font-weight: bold;
	line-height: 65px;
	margin: 0;
	padding: 30px 0;
}
.port-project-info {
	width: 100%;
	float: left;
	padding: 40px;
	border: 1px solid #f1f1f1;
	li {
		padding: 0 0 10px 0;
		margin: 0px;
		span {
			color: $color_8;
			display: inline-block;
			font-weight: 700;
			width: 150px;
			i {
				margin-right: 7px;
				position: relative;
				text-align: center;
				top: 1px;
				width: 14px;
			}
		}
	}
}
.bg-cinput {
	background-color: $background-color_2;
	border: 1px solid #e9e9e9;
	color: $color_8;
	float: left;
	height: 45px;
	margin-bottom: 15px;
	padding: 8px;
	transition: all 0.3s ease-out 0s;
	width: 100%;
}
.bg-ctexteria {
	background-color: $background-color_2;
	border: 1px solid #e9e9e9;
	color: $color_8;
	float: left;
	margin-bottom: 15px;
	padding: 8px;
	transition: all 0.3s ease-out 0s;
	width: 100%;
	height: 160px;
}
ul.blog-pagenation {
	margin: 30px 0 0 0;
	padding: 0;
	text-align: center;
	width: 100%;
}
.blog-pagenation {
	li {
		display: inline;
		margin: 0;
		padding: 0;
		a {
			border: 1px solid #e9e9e9;
			color: $color_8;
			display: inline-block;
			height: 40px;
			line-height: 40px;
			margin: 0 10px 0 0;
			padding: 0;
			text-align: center;
			width: 40px;
			background-color: $background-color_2;
			&:hover {
				background-color: $background-color_1;
				border: 1px solid #ff971c;
				color: $color_7;
			}
		}
		a.active {
			background-color: $background-color_1;
			border: 1px solid #ff971c;
			color: $color_7;
		}
	}
}
.bg2-video-wrapper {
	width: 100%;
	float: left;
	padding: 0px;
	iframe {
		width: 700px;
		height: 415px;
	}
}
.flexslider {
	.feature-box-1 {
		position: absolute;
		width: 700px;
		padding: 100px;
		float: left;
		margin: 150px 0 0 200px;
		z-index: 2;
		background-color: $background-color_1;
	}
}
.ce-newsletter-1 {
	width: 100%;
	height: 60px;
	float: left;
	padding: 20px;
	border-radius: 40px;
	border: 1px solid #fff;
	background-color: $background-color_2;
}
.newsletter-submit-btn {
	width: 100%;
	height: 60px;
	float: left;
	padding: 0px;
	margin-top: 5px;
	color: $color_7;
	text-transform: uppercase;
	border-radius: 40px;
	border: 1px solid #ff971c;
	background-color: $background-color_1;
}
.cb-feature-box-1 {
	width: 100%;
	float: left;
	transition: all 0.3s ease-out 0s;
	.img-box {
		img {
			transition: all 0.3s ease-out 0s;
		}
		position: relative;
		width: 100%;
		float: left;
		overflow: hidden;
		.postdate-box {
			position: absolute;
			float: left;
			padding: 50px;
			z-index: 2;
			right: 0px;
			bottom: 0px;
			background-color: $background-color_1;
		}
		.blog-post-info {
			span {
				font-size: 18px;
				color: $color_7;
			}
		}
		.postdate-box.less-padd-1 {
			padding: 20px;
		}
		.postdate-box.less-padd-2 {
			padding: 10px;
		}
	}
	&:hover {
		.img-box {
			img {
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				transform: scale(1.3);
			}
		}
	}
	.postinfo-box {
		width: 100%;
		float: left;
		padding: 100px;
		margin: 0px 0 40px 0;
		background-color: $background-color_2;
		.title {
			a {
				&:hover {
					color: $color_24;
				}
			}
		}
	}
}
.cb-feature-box-1.hover-shadow {
	&:hover {
		-webkit-box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
		-moz-box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
		box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
	}
}
.cb-feature-box-1.active.hover-shadow {
	-webkit-box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 40px -1px rgba(0,0,0,0.1);
}
.cb-feature-box-1.border {
	border: 1px solid #e9e9e9;
}
.cb-feature-box-1.less-padd {
	.postinfo-box {
		padding: 60px;
	}
}
.cb-feature-box-1.less-padd-2 {
	.postinfo-box {
		padding: 80px;
	}
}
.cb-feature-box-1.no-margin {
	.postinfo-box {
		margin: 0px 0 0 0;
	}
}
.cb-featurebox-2 {
	width: 100%;
	float: left;
	padding: 30px 13px;
	border: 1px solid #e4e4e4;
	background-color: $background-color_2;
}
#form_status {
	span {
		color: $color_7;
		font-size: 14px;
		font-weight: normal;
		background: #E74C3C;
		width: 100%;
		text-align: center;
		display: inline-block;
		padding: 10px 0px;
		border-radius: 3px;
		margin-bottom: 18px;
	}
	span.loading {
		color: $color_28;
		background: #eee;
		border-radius: 3px;
		padding: 18px 0px;
	}
	span.notice {
		color: $color_29;
	}
	.success {
		color: $color_7;
		text-align: center;
		background: #2ecc71;
		border-radius: 3px;
		padding: 30px 0px;
		i {
			color: $color_7;
			font-size: 45px;
			margin-bottom: 14px;
		}
		h3 {
			color: $color_7;
			margin-bottom: 10px;
		}
	}
}
.cforms {
	label {
		float: left;
		width: 100%;
		color: $color_2;
		font-weight: 600;
		em {
			color: $color_2;
			font-size: 12px;
			font-style: normal;
		}
	}
	input {
		color: $color_7;
		float: left;
		width: 100%;
		padding: 9px 12px;
		margin-bottom: 10px;
		border: none;
		background: none;
		-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,0.4);
		-moz-box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,0.4);
		box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,0.4);
	}
	textarea {
		color: $color_7;
		float: left;
		width: 100%;
		padding: 9px 12px;
		margin-bottom: 10px;
		border: none;
		background: none;
		-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,0.4);
		-moz-box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,0.4);
		box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,0.4);
	}
	.button {
		float: left;
		margin-top: 10px;
		color: $color_7;
		padding: 12px 20px;
		font-size: 14px;
		font-weight: 600;
		background: #676767;
		border: 1px solid #676767;
		border-radius: 3px;
		font-family: $font-family_1;
		cursor: pointer;
		text-transform: uppercase;
		transition: all 0.3s ease;
		&:hover {
			background: #999;
			border: 1px solid #999;
		}
	}
}
.cforms_sty2 {
	label {
		float: left;
		width: 100%;
		color: $color_7;
		font-weight: 600;
		text-align: left;
		em {
			color: $color_7;
			font-size: 12px;
			font-style: normal;
		}
	}
	input {
		color: $color_7;
		float: left;
		width: 100%;
		padding: 9px 12px;
		margin-bottom: 12px;
		border: 1px solid #fff;
		background: none;
		-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,1);
		-moz-box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,1);
		box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,1);
	}
	textarea {
		color: $color_7;
		float: left;
		width: 100%;
		padding: 9px 12px;
		margin-bottom: 10px;
		border: 1px solid #fff;
		background: none;
		-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,1);
		-moz-box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,1);
		box-shadow: inset 0px 0px 1px 0px rgba(255,255,255,1);
	}
	.button {
		margin-top: 30px;
		color: $color_30;
		padding: 18px 34px;
		font-size: 14px;
		font-weight: 600;
		background: #fff;
		border: 1px solid #fff;
		border-radius: 3px;
		font-family: $font-family_1;
		cursor: pointer;
		text-transform: uppercase;
		letter-spacing: 1px;
		transition: all 0.3s ease;
		&:hover {
			color: $color_7;
			background: #2ecc71;
			border: 1px solid #2ecc71;
		}
	}
}
.form-input-hp{
	position: absolute;
	top: -2000px;
	left: -2000px;
}
.cforms_sty3 {
	label {
		width: 100%;
		color: $color_31;
		font-size: 14px;
		font-weight: normal;
		em {
			color: $color_32;
			font-size: 12px;
			font-style: normal;
		}
	}
	input {
		color: $color_31;
		float: left;
		width: 100%;
		padding: 9px 12px;
		margin-bottom: 10px;
		border: 1px solid #eee;
		background: #f9f9f9;
		-webkit-box-shadow: inset 1px 0px 2px 0px rgba(238,238,238,1);
		-moz-box-shadow: inset 1px 0px 2px 0px rgba(238,238,238,1);
		box-shadow: inset 0px 1px 2px 0px rgba(238,238,238,1);
	}
	textarea {
		color: $color_31;
		float: left;
		width: 100%;
		padding: 9px 12px;
		margin-bottom: 10px;
		border: 1px solid #eee;
		background: #f9f9f9;
		-webkit-box-shadow: inset 1px 0px 2px 0px rgba(238,238,238,1);
		-moz-box-shadow: inset 1px 0px 2px 0px rgba(238,238,238,1);
		box-shadow: inset 0px 1px 2px 0px rgba(238,238,238,1);
	}
	.button {
		float: left;
		margin-top: 10px;
		color: white;
		background: $colorPrimary;
		border: 1px solid $colorPrimary;
		padding: 14px 20px;
		font-size: 14px;
		font-weight: 500;
		border-radius: 3px;
		font-family: $font-family_1;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			background: white;
			color: $colorPrimary;
			border: 1px solid $colorPrimary;
		}
	}
	.button2 {
		float: left;
		margin-top: 10px;
		color: $color_7;
		padding: 14px 20px;
		font-size: 14px;
		font-weight: 500;
		background: #d2527f;
		border: 1px solid #d2527f;
		border-radius: 3px;
		font-family: $font-family_1;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			background: #727272;
			border: 1px solid #393939;
		}
	}
	.button3 {
		float: left;
		margin-top: 10px;
		color: $color_7;
		padding: 18px 30px;
		font-size: 14px;
		font-weight: 500;
		background: #db6364;
		border: 1px solid #db6364;
		border-radius: 3px;
		font-family: $font-family_1;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			background: #454545;
			border: 1px solid #454545;
		}
	}
}
#map {
	width: 100%;
	height: 400px;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
@media screen and (max-width: 1024px) {
	.ce-feature-box-9-main {
		.ce-feature-box-9 {
			position: static;
			margin: 100px 0;
		}
	}
	.ce-feature-box-10 {
		.content.pos-1 {
			padding: 230px 0 0 0;
		}
		.play-btn-img {
			top: 20%;
		}
		.content {
			padding: 170px 0 0 0;
		}
	}
	.ce-feature-box-11 {
		.img-box {
			.overlay {
				.text-box {
					top: 19px;
				}
			}
		}
		&:hover {
			.img-box {
				.overlay {
					.text-box {
						top: 0;
					}
				}
			}
		}
	}
	.ce-feature-box-33 {
		.shape-1 {
			display: none;
		}
		.shape-2 {
			display: none;
		}
		.shape-3 {
			display: none;
		}
		.shape-4 {
			display: none;
		}
		.shape-5 {
			display: none;
		}
	}
	.ce-feature-box-48 {
		.text-box {
			padding: 40px;
		}
	}
	.ce-feature-box-2 {
		padding: 40px;
	}
	.ce-feature-box-44 {
		.name-box {
			bottom: -38%;
		}
		&:hover {
			.img-box {
				.overlay {
					.text-box {
						top: 60px;
					}
				}
			}
		}
	}
	.ce-feature-box-49 {
		.text-box.pos-2 {
			left: 0;
		}
	}
	.ce-feature-box-54 {
		margin-bottom: 0;
	}
}
@media only screen and (max-width: 991px) {
	.ce-feature-box-9-main {
		.ce-feature-box-9 {
			position: static;
			margin: 20px 0;
		}
	}
	.ce-feature-box-65 {
		.img-box {
			position: static;
			margin-top: 30px;
		}
		.pattren-box {
			display: none;
		}
	}
	.ce-feature-box-54 {
		padding: 90px 90px;
	}
	.ce-feature-box-33 {
		.img-box {
			.overlay {
				top: 615px;
			}
		}
	}
	.ce-feature-box-40 {
		position: static;
		.pattren-box {
			position: static;
			position: static;
			height: 715px;
		}
	}
	.ce-feature-box-49 {
		.text-box {
			position: static;
		}
		position: static;
	}
	.ce-feature-box-19 {
		.img-box {
			.overlay {
				top: 865px;
			}
		}
	}
	.ce-feature-box-55 {
		margin-bottom: 30px;
	}
}
@media only screen and (max-width: 768px) {
	.ce-feature-box-54 {
		position: static;
	}
	.ce-feature-box-10 {
		.content.pos-1 {
			padding: 140px 0 0 0;
		}
		.play-btn-img.pos-1 {
			top: 15%;
		}
	}
	.ce-feature-box-64 {
		position: static;
	}
	.ce-feature-box-64.bmargin {
		margin-bottom: 45px;
	}
	.ce-feature-box-55 {
		margin-bottom: 20px;
		.text-box {
			position: static;
			width: 100%;
			padding: 40px 36px;
			top: 0;
			left: 0;
		}
	}
}
@media screen and (max-width: 640px) {
	.ce-feature-box-10 {
		.play-btn-img {
			img {
				width: 40%;
			}
		}
		.content {
			padding: 100px 0 0 0;
		}
	}
	.ce-feature-box-44 {
		.name-box {
			width: 100%;
			left: 0;
			bottom: -25%;
		}
		margin-bottom: 180px;
	}
	.ce-feature-box-55 {
		.text-box {
			position: static;
			width: 100%;
			top: 0;
			left: 0;
		}
	}
	.ce-feature-box-16 {
		.main-box {
			.inner-box {
				position: static;
			}
		}
	}
	.ce-feature-box-40 {
		.pattren-box {
			position: static;
			height: 605px;
		}
	}
	.ce-feature-box-59 {
		.img-box {
			.overlay {
				a.btn {
					top: 695px;
				}
			}
		}
		&:hover {
			.img-box {
				.overlay {
					a.btn {
						top: 300px;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 480px) {
	.ce-feature-box-54 {
		.newsletter {
			width: 100%;
		}
		.newsletter-btn {
			width: 100%;
		}
	}
	.ce-feature-box-10 {
		.content.pos-1 {
			padding: 79px 0 0 0;
			h2 {
				font-size: 18px;
			}
		}
		.content {
			h2 {
				font-size: 20px;
			}
		}
	}
	.ce-feature-box-29 {
		position: static;
		margin-bottom: 30px;
	}
	.ce-feature-box-40 {
		.pattren-box {
			height: 465px;
		}
	}
	.ce-feature-box-49 {
		.text-box {
			width: 100%;
		}
	}
}
@media screen and (max-width: 360px) {
	.ce-feature-box-44 {
		margin-bottom: 120px;
		&:hover {
			.img-box {
				.overlay {
					.text-box {
						top: 50px;
					}
				}
			}
		}
	}
	.ce-feature-box-55 {
		margin-bottom: 40px;
	}
	.ce-feature-box-48 {
		.text-box {
			padding: 60px;
		}
	}
	.ce-sub-text {
		width: 90%;
	}
	.ce-feature-box-35 {
		.newsletter {
			width: 100%;
		}
		.newsletter-btn {
			width: 100%;
		}
	}
	.ce-feature-box-40 {
		.pattren-box {
			height: 350px;
		}
		.play-btn-img {
			top: 105px;
			img {
				width: 45%;
			}
		}
	}
}
@media only screen and (max-width: 320px) {
	.ce-feature-box-44 {
		&:hover {
			.img-box {
				.overlay {
					.text-box {
						top: 30px;
					}
				}
			}
		}
	}
	.ce-feature-box-69 {
		.shape-2.pos-3 {
			left: 180px;
		}
		.shape-4.pos-1 {
			left: 225px;
		}
	}
}
