footer{
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  h4{
    margin-top: 20px;
  }
  .contact{
    display: block;
    padding-top: 20px;
  }
  @include devices(tablet) {
    .footer-logo {
      margin: 0 auto;
      &:first-child{
        padding-top: 20px;
      }
    }
    address, span, .read-more{
      padding-left: 20px;
    }
  }
}