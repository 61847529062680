.etax-feature-box {
  position: relative;
  display: flex;
  height: 100%;
  .main-box{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .img-box {
      width: 100%;
      padding: 0px;
      position: relative;
      overflow: hidden;
      .subtext{
        color: $colorPrimary;
      }
      img{
        position: relative;
      }
    }
  }
  .text-box{
    background-color: $colorLight;
    padding: 0;
    color: $colorDark;
    overflow: hidden;
    flex: max-content;
    display: flex;
  }
  .inner-box {
    width: 100%;
    padding: 60px;
    background-color: $colorLight;
    transition: all 0.3s ease-out 0s;
    .title {
      color: $colorDark;
    }
    .subtext {
      color: $colorPrimary;
      transition: all 0.3s ease-out 0s;
    }
    .divider-line{
      border-bottom-color: $color_9;
    }
    .small-text {
      color: $colorDark;
      opacity: 1;
    }
    p{
      color: $colorDark;
    }
    ul{
      padding: 0;
      li{
        i{
          font-size: 20px;
        }
      }
    }
  }

}
/*
.ce-feature-box-16 {
  width: 100%;
  float: left;
  .main-box {
    position: relative;
    width: 100%;
    padding: 0px;
    float: left;
    overflow: hidden;
    .img-box {
      width: 100%;
      padding: 0px;
      float: left;
      position: relative;
      width: 100%;
      float: left;
      overflow: hidden;
    }
    img {
      transition: all 0.3s ease-out 0s;
    }
    .text-box {
      width: 100%;
      min-height: 260px;
      padding: 0px;
      float: left;
    }
    .inner-box {
      position: absolute;
      width: 100%;
      padding: 60px;
      float: left;
      top: 470px;
      background-color: $background-color_2;
      transition: all 0.3s ease-out 0s;
      .title {
        color: $color_6;
      }
      .subtext {
        color: $color_5;
        transition: all 0.3s ease-out 0s;
      }
      .small-text {
        color: $color_4;
        opacity: 1;
      }
    }
    .inner-box.bg-dark {
      background-color: $background-color_23;
    }
  }
  &:hover {
    .img-box {
      img {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    .main-box {
      .inner-box {
        .title {
          color: $color_7;
        }
        .subtext {
          color: $color_7;
          transition: all 0.3s ease-out 0s;
          color: $color_7;
        }
        .small-text {
          color: $color_7;
          opacity: 1;
          color: $color_7;
          opacity: 1;
        }
      }
    }
    .text-box {
      .inner-box {
        top: 400px;
        background-color: $background-color_4;
      }
    }
  }
  .text-box {
    .inner-box {
      .sc-icons {
        width: 100%;
        float: left;
        padding: 30px 0 0 0;
        transition: all 0.3s ease-out 0s;
        li {
          padding: 0px;
          margin: 0px;
          display: inline-block;
          a {
            width: 40px;
            height: 40px;
            float: left;
            font-size: 15px;
            color: $color_7;
            line-height: 0px;
            margin: 0px;
            display: inline-block;
            border-radius: 100%;
            &:hover {
              color: $color_7;
            }
          }
        }
      }
    }
  }
}
*/

.ce-feature-box-16{
  .main-box {
    .inner-box{
      .subtext{
        color: $colorPrimary;
      }
    }
  }
}