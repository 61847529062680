.blog-article-container{

  @include devices(tablet) {
    padding: 0;
  }
  ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    margin-top: 40px;
    padding: 0;
    justify-content: flex-start;

    li.blog-article{
      list-style: none;
      margin-bottom: 40px;
      box-shadow: 20px 20px 20px rgba(0,0,0,.03);
      position: relative;
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      background-color: white;
      min-height: 500px;
      @include devices(tablet) {
        width: 100%!important;
        margin: 0 0 40px!important;
      }
      a{
        display: flex;
        flex-direction: column;
        flex: max-content;
        height: 100%;
        color: #272727;
        position: relative;
        .article-image{
          position: relative;
          width: 100%;
          padding: 0;
          img{
            width: 100%;
          }
          .info-badge{
            position: absolute;
            right: 20px;
            bottom: 0;
            color: #000;
            padding: 5px 20px;
            background-color: #fff;
            z-index: 3;
            svg{
              top: 3px;
              position: relative;
              width: 16px;
              height: auto;
              left: -5px;
              color: $colorDark;
              fill: $colorDark;
            }
            i{
              margin-right: 5px;
            }
          }
        }
        .article-text{
          padding: 40px 40px 70px;
          width: 100%;
          outline: 0 !important;
          h3{
          }
          h4{
            margin-top: 5px;
            font-size: 20px;
          }
          p{
            margin-top: 10px;
          }
        }
        .article-date{
          color: #afafaf;
          position: absolute;
          border-top: 1px solid #e9e9e9;
          bottom: 20px;
          margin: 0 40px;
          width: calc(100% - 80px);
          .box-left{
            padding-top: 15px;
            i {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
#blog { // blog overview template

  #blog-detail img, .yt-video-wrapper{
    width: 50%;
    margin: 0 0 30px 30px;
    float: right;
    @include devices(tablet) {
      width: 100%;
      float: none;
      margin: 20px 0;
    }
  }
  #blog-detail{
    .textImage{
      padding-top: 30px;
    }
    .tags-container{
      border-top: 1px solid #e9e9e9;
      content: " ";
      display: block;
      width: 100%;
      position: relative;
      padding-top: 10px;
      clear: both;
      .tags{
        margin-bottom: 15px;
        color: #0f3e68;
        display: inline-block;
        padding: 0;
        li{
          list-style: none;
          margin-right: 10px;
          background-color: $colorLight;
          border: 1px solid #808080;
          float: left;
          border-radius: 20px;
          padding: 2px 10px;
          color: $colorDark;
          margin-bottom: 10px;
          svg{
            top: 2px;
            position: relative;
            width: 16px;
            height: auto;
            color: $colorDark;
            fill: $colorDark;
          }
        }
      }
    }
  }
  .yt-video{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  p {
    margin-bottom: 20px;
  }

  .blog-share-icons-wrapper{
    padding: 50px;
    @include devices(tablet) {
      padding: 50px 0;
    }
    .share-claim{
      text-align: center;
      margin-bottom: 50px;
      h4{
        font-size: 24px; font-weight: bold
      }
    }
    .blog-share-icons{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 100%;
      margin-top: 40px;
      padding: 0;
      justify-content: space-evenly;
      font-size: 60px;
      li{
        list-style: none;
        flex-direction: column;
      }
    }
  }
  .back-to-blog-overview{
    text-align: center;
    margin: 40px
  }
}